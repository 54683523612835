import HTTP from '../helper/http';

const GeneralModel =
{
    getOverview: async function()
    {
        var url = 'https://admin.pup.a2hosted.com/general/getOverview';

        

        return await HTTP.post(url, {}).then(response =>{return response;})
    },
}

export default GeneralModel;