import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import SettlementView from '../../view/page/settlement/settlementView';

import DriverModel from "../../model/driver";
import Session from '../../helper/session';

export default class SettlementIDViewController extends ViewController 
{
	constructor(props) {
		super(props);

		this.state =
		{
			driver_id:props.match?.params?.id,
			driver:{},
        }
	}

	getInfo()
	{
        this._isMounted && this.startLoading()
        this._isMounted && DriverModel
        ?.getDriverPaymentDueInfo(this.state?.driver_id)
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, driver:data?.data});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
    }

	save(price)
	{
		if(price===null)
        {
            this._isMounted && this.showErrMsg(this.i18n('validation_allFieldsAreRequired'), "error");
        }
		else
		{
			this._isMounted && this.startLoading()
			this._isMounted && DriverModel
			?.settle(this.state?.driver_id, price)
			.then(async data =>
			{
				if(parseInt(data?.result) === 1)
				{
					this._isMounted && this.getInfo();
					this._isMounted && this.showErrMsg(this.i18n("msg_changesSaved"), "success");
					
				}
				else
				{
					this._isMounted && this.showErrMsg(data?.message, "error");
				}

			})
			.catch(e =>
			{
				this._isMounted && this.stopLoading();		
			});
		}
	}

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}
    
	viewControllerDidMount()
	{
		this._isMounted && this.getInfo();
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<SettlementView
						driver={this.state?.driver}
						save={this.save.bind(this)}
					/>
					
				</>
			);
		}
        else 
        {
            window.location.href = "/login";
        }
	}
}