import HTTP from '../helper/http';

const AdminModel =
{
    getAll: async function(pageNumber,timestamp)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/getAll';

        var postData = new FormData();
        postData.append("page_number", pageNumber);
        postData.append("timestamp", timestamp);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getInfo: async function(id)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/getInfo';

        var postData = new FormData();
        postData.append("id", id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    login: async function(identifier,lang)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/login';

        var postData = new FormData();

        postData.append('identifier', identifier);
        postData.append('lang', lang);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    verifyOTP: async function(identifier,otp)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/verifyOTP';

        var postData = new FormData();
        postData.append('identifier', identifier);
        postData.append('otp', otp);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    add: async function(formData)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/add';

        var postData = new FormData();
        Object.keys(formData)?.map(key => 
        {
            if("roles" === key)
            {
                postData.append("roles", JSON.stringify(formData[key]) )
            }
            else
            {
                return formData[key] !== '' ? postData.append(key, formData[key]) : null ;
            }
            
        });

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getRoles: async function()
    {
        var url = 'https://admin.pup.a2hosted.com/admin/getRoles';

        return await HTTP.post(url, ).then(response =>{return response;})
    },

    addRole: async function(admin_id,role_id)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/addRole';

        var postData = new FormData();
        postData.append('admin_id', admin_id);
        postData.append('role_id', role_id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    removeRole: async function(admin_id,role_id)
    {
        var url = 'https://admin.pup.a2hosted.com/admin/removeRole';

        var postData = new FormData();
        postData.append('admin_id', admin_id);
        postData.append('role_id', role_id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    }


}

export default AdminModel;