import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';

import avatar_user from "../../../assets/images/avatar_user.png";

export default class settlementView extends View
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            price:null,
        }

    }

    handleChange(e)
    {
        this._isMounted && this.setState({...this.state, [e.target.name]:e.target.value});
    }

    save()
    {
        this._isMounted && this.props?.save(this.state?.price); 
    }
    
    render()
    {  
        const prefs = Session.getPreferences();
        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-5 `}>
                            <Col xs={12} sm={6} className={`p-0 m-0`}>
                                <Row className={`m-0 p-0 py-4`}>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading t-alternative-with-alpha`}>
                                        {
                                            <div style={{backgroundImage : this.props?.driver?.profile_pic ? `url(${this.props?.driver?.profile_pic})` : `url(${avatar_user})`}} className={`manageUserView-user-avatar`}/>
                                        }
                                    </Col>
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_displayName')}:`}
                                    </Col>
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.name}
                                    </Col>
                                </Row>
                                <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('formAttribute_email')}:`}
                                    </Col>
                                    {
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                            {this.props?.driver?.name}
                                        </Col>
                                    }
                                </Row>
                                <Row className={`m-0 py-3 d-flex align-items-center font-md t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('formAttribute_mobile')}:`}
                                    </Col>
                                    {
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_mobile_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                            {this.props?.driver?.mobile}
                                        </Col>
                                    }
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_amount')}:`}
                                    </Col>
                                    {
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 manageUserView-notVerified`}>
                                            {this.props?.driver?.total_due}
                                        </Col>
                                    } 
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_bank')}:`}
                                    </Col>
                                    {
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 manageUserView-notVerified`}>
                                            {this.props?.driver?.bank_name}
                                        </Col>
                                    } 
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_iban')}:`}
                                    </Col>
                                    {
                                        
                                        
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 manageUserView-notVerified`}>
                                            {this.props?.driver?.bank_IBAN}
                                        </Col>
                                    }     
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_price')}:`}
                                    </Col>
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="number"
                                            name="price"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_price')}
                                        />
                                    </Col>
                                </Row>

                                <Row className={`m-0 pt-5 pb-4 pe-4 font-md t-text-alternative-with-alpha`}>
                                    <CustomButton
                                        iconAndText={false}
                                        viewIsLoading={false}
                                        // disabled={((this.state?.price !==null))}
                                        style={`p-3 w-100 borderless t-light-custom-disabled-button  t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                        text={this.i18n('action_save')}
                                        action={this.save.bind(this)}
                                    />
                                </Row>

                            </Col>
                            
                        </Row>
                   
            </Container>
        );
    }
}