import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import AdminView from '../../view/page/admin/adminView';

import AdminModel from "../../model/admin";

import Session from '../../helper/session';

export default class AdminIdViewController extends ViewController {
	constructor(props) {
		super(props);

		this.state =
		{
			admin_id:props.match?.params?.id,
			admin:{},
			roles:[],
        }
	}

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}

	getInfo()
	{
        this._isMounted && this.startLoading()
        this._isMounted && AdminModel
        ?.getInfo(this.state?.admin_id)
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, admin:data?.data});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
    }

	getRoles()
	{
		this._isMounted && this.startLoading()
        this._isMounted && AdminModel
        ?.getRoles()
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, roles:data?.data});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
	}

	updateRoles(role)
	{
		if(role?.is_added)
		{
			this._isMounted && AdminModel
			?.removeRole(this.state?.admin_id, role?.id)
			.then(async data =>
			{
	
				if(parseInt(data?.result) === 1)
				{
					let temp = this.state?.admin;
					temp?.allRoles?.forEach(item =>
					{
						if(item?.id === role?.id)
						{
							item['is_added'] =  0;
						}
					})
					this._isMounted && this.setState({...this.state, admin:temp});
				}
				else
				{
					this._isMounted && this.showErrMsg(data?.error_msg, "error");
	
				}
			})
			.catch(e =>
			{
				this._isMounted && this.stopLoading();		
			});
		}
		else
		{
			this._isMounted && AdminModel
			?.addRole(this.state?.admin_id, role?.id)
			.then(async data =>
			{
	
				if(parseInt(data?.result) === 1)
				{
					let temp = this.state?.admin;
					temp?.allRoles?.forEach(item =>
					{
						if(item?.id === role?.id)
						{
							item['is_added'] =  1;
						}
					})
					this._isMounted && this.setState({...this.state, admin:temp});
				}
				else
				{
					this._isMounted && this.showErrMsg(data?.error_msg, "error");
	
				}
			})
			.catch(e =>
			{
				this._isMounted && this.stopLoading();		
			});
		}
	}
    
	viewControllerDidMount()
	{
		this._isMounted && this.getInfo();
		this._isMounted && this.getRoles();
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<AdminView
						admin={this.state?.admin}
						roles={this.state?.roles}
						updateRoles={this.updateRoles.bind(this)}
					/>

					
				</>
			);
		}
		else 
		{
			window.location.href = "/login";
		}
	}
}