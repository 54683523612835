import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';

import CustomButton from "../../component/button/customButton.js";

import Session from '../../../helper/session';
import AppConfigs from '../../../config/appConfigs';

import './loginView.scss';
import SmallModal from '../../component/smallModal/smallModal';


export default class LoginView extends View
{

    constructor(props)
	{
        super(props)
	
		this.state = 
		{
            loginInfo: 
            {
                identifier: '',
                password: '',
            },
            phoneNo:'',
			isMobileInput: false,
		}

        this._servedCountries=[
            {
                country_code_iso_2: "BH",
                country_code_iso_3: "BHR",
                country_id: 48,
                name: "Bahrain",
                phone_code_iso: "973",
                phone_number_length: null,
                phone_prefix_number: null
            },
            {
                country_code_iso_2: "SA",
                country_code_iso_3: "SAU",
                country_id: 682,
                name: "Saudi Arabia",
                phone_code_iso: "966",
                phone_number_length: 12,
                phone_prefix_number: 5,
            },
            {
                country_code_iso_2: "IN",
                country_code_iso_3: "IND",
                country_id: 680,
                name: "India",
                phone_code_iso: "91",
                phone_number_length: 10,
                phone_prefix_number: 2,
            }
        ];
        this._PhoneCountryCode = AppConfigs?.defualtCountryPhoneCode;

	}

    handleSwitch()
    {
        this._isMounted && this.setState({...this.state, isMobileInput: !this.state.isMobileInput});
    }

    handleForgotPassword()
    {
        this._isMounted && this.props?.handleResetPasswordEvent(this.state.isMobileInput);
    }

    loginForm({ target: { name, value } })
    {
        let loginInfo = this.state.loginInfo;
        loginInfo[name] = value;
        this._isMounted && this.setState({...this.state, loginInfo: loginInfo})
    }

    handlePhoneNumberChange(value)
    {
        let loginInfo = this.state?.loginInfo;
        let mobileWithPhoneCoutryCode = `${this._PhoneCountryCode}${value}`;
        loginInfo["identifier"] = mobileWithPhoneCoutryCode;
        this._isMounted &&  this._isMounted && this.setState({...this.state, loginInfo: loginInfo})
    }

    handlePasswordFormChanges(e)
    {
        this._isMounted && this.setState({...this.state, passwordForm:{...this.state.passwordForm, [e.target.name]:e.target.value}});
    }

    handleCountryCodeChange(phone_code)
    {
        this._PhoneCountryCode = phone_code;
    }

    login(identifier)
    {
        this._isMounted && this.props.login(identifier);
    }

    onKeyDownSubmit()
    {
        if(this.props.viewIsLoading || (this.state.loginInfo.identifier && this.state.loginInfo.password))
        {
            this._isMounted && this.props.login(this.state.loginInfo.identifier , this.state.loginInfo.password);
        }
    }

    render()
    {  
        const prefs = Session.getPreferences();

        return ( 
            <Container className="themed-container m-0 p-0 h-100" fluid={true}>
                <Row className="m-0 p-4 h-100 justify-content-center">
                    <Col className={`m-0 p-0 loginView-col align-self-center`}>
                        <div className={` p-0 ${prefs?.theme === "light" ? 't-light-bg-exception' : `t-bg-secondary`} t-text-alternative-highlight rounded-sm`}>
                           <SmallModal
                                shouldShowBackButton={false} 
                           />
                            <Container className={`themed-container m-0 p-0 px-2 px-md-4 ${prefs?.dir}-secondaryFont t-text-alternative text-center`} fluid={true}>
                                <Row className={`m-0 p-0 pb-3 t-text-alternative-highlight`}>
                                    <p className={`p-0 pb-3 font-lg ${prefs?.dir}-primaryFont`}>
                                        {this.i18n('title_login')}
                                    </p>
                                </Row>
                                <Row className={`m-0 p-0 px-3 px-md-0`}>
                                    <div className={`m-0 p-0 pt-2 ${prefs.dir}-pull-to-leading loginView-dropdown`}>
                                        <div className={`m-0 p-0 py-3`}>
                                            <span className={`pt-2 t-text-alternative-highlight font-md pointer`}>{this.state.isMobileInput ? this.i18n('formAttribute_mobile') : this.i18n('formAttribute_email')}</span>
                                        </div>
                                        {this.state.isMobileInput
                                        ?
                                            <input
                                                onChange={this.loginForm.bind(this)}
                                                type="text"
                                                name="identifier"
                                                defaultValue={""}
                                                autoComplete="off"
                                                className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                                placeholder={"+966-XXXXXXX"}
                                                onKeyDown={e => e.key === 'Enter' && this.onKeyDownSubmit()}
                                            />
                                        :
                                            <>
                                                <input
                                                    onChange={this.loginForm.bind(this)}
                                                    type="text"
                                                    name="identifier"
                                                    defaultValue={""}
                                                    autoComplete="off"
                                                    className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                                    placeholder={this.i18n('formPlaceholder_login_by_email')}
                                                    onKeyDown={e => e.key === 'Enter' && this.onKeyDownSubmit()}
                                                />
                                            </>
                                        }
                                        <div className={`m-0 p-0 pt-3 pb-2 ${prefs?.dir}-pull-to-trailing`}>
                                            <span onClick={this.handleSwitch.bind(this)} className={`pt-3 t-text-accent font-md pointer`}>{this.state.isMobileInput ? this.i18n('formAttribute_login_by_email') : this.i18n('formAttribute_login_by_mobile')}</span>
                                        </div>
                                    </div>
                                    <div className={`m-0 p-0 pt-3 pb-4`}>
                                        <CustomButton
                                            iconAndText={false}
                                            viewIsLoading={this.props.viewIsLoading}
                                            disabled={this.props.viewIsLoading || !(this.state.loginInfo.identifier)}
                                            style={`buttons-padding w-100 borderless custom-modal-button ${prefs?.dir}-secondaryFont font-md t-bg-accent t-text-alternative-highlight rounded-sm`}
                                            text={this.i18n('title_login')}
                                            action={this.login.bind(this, this.state.loginInfo.identifier, this.state.loginInfo.password)}
                                        />
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}