import View from '../../../core/view';

import { Container, Row, Col } from 'reactstrap';

import Session from '../../../helper/session';

import './overviewView.scss';

export default class OverallListView extends View 
{
    constructor(props)
	{
		super(props);

		this.state =
		{

        }
	}

    render() 
    {
        const prefs = Session.getPreferences();
        console.log(this.props?.overall);
        return (
            <Container className={`p-3 pt-4 m-0 ${prefs?.dir}-secondaryFont t-bg-primary font-md t-text-alternative-highlight rounded-sm h-100`}>
                <div className={` t-text-primary-highlight ${prefs?.dir}-pull-to-leading`}>
                    {this.props?.viewIsLoading 
                        ?
                        <>
                        </>
                        :
                    <>
                    {
                        
                            <>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder mt-4 my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md `}>
                                            {`${this.i18n('common_totalCustomers')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_customers}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalUsers')}:`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_users}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalDrivers')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_drivers}                                        
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalOrders')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_orders}   
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalCompleteOrders')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_complete_orders} 
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalIncome')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_income}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`p-0 m-0 py-2 overallViewList-permissonBorder my-2`}>
                                    <Col xs={7} sm={3} md={3} lg={3} xl={2} className="p-0 m-0">
                                        <div className={`${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-pull-to-leading`}>
                                            {`${this.i18n('common_totalProfit')} :`}
                                        </div>
                                    </Col>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading px-2`}>
                                        <div className={`${prefs?.dir}-secondaryFont font-md overallViewList-color`}>
                                            {this.props?.overall?.total_profit}
                                        </div>
                                    </Col>
                                </Row>
                                
                            </>

                    }
                    </>
                    }
                </div>
            </Container >
        )
    }
}