import View from '../../../core/view';

import Session from '../../../helper/session';

import OverallListView from './overallListView';

import {Container, Row} from 'reactstrap';

export default class OverviewView extends View
{
    handleTimeStamp(date)
    {
        
    }

    handleActivityTimeStamp(date)
    {
    }
    
    handleSalesTimeStamp(date)
    {
    }

    handleGamesTimeStamp(date)
    {
    }

    render ()
    {
        const prefs = Session.getPreferences();
        return (
            <Container className={`themed-container h-100 p-0 m-0 font-md scrollable-y ${prefs?.dir}-secondaryFont t-bg-primary-highlight`} fluid={true}>
                
                <Row className="p-0 m-0 py-5">
                    <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                        {this.i18n('title_overview')}
                    </div>
                    <OverallListView overall={this.props?.overalldata} viewIsLoading={this.props?.viewIsLoading}/>
                </Row>
                
            </Container>
        )
    }
}