import HTTP from '../helper/http';

const DriverModel =
{
    getAll: async function(pageNumber,timestamp,status)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/getAll';

        var postData = new FormData();
        postData.append("page_number", pageNumber);
        postData.append("timestamp", timestamp);
        if(status !== null)
        {
            postData.append('status', status)
        }

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getInfo: async function(id)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/getInfo';

        var postData = new FormData();
        postData.append("id", id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getDriversPaymentDues: async function(pageNumber,timestamp)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/getDriversPaymentDues';

        var postData = new FormData();
        postData.append("page_number", pageNumber);
        postData.append("timestamp", timestamp);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getDriverPaymentDueInfo: async function(id)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/getDriverPaymentDueInfo';

        var postData = new FormData();
        postData.append("driver_id", id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    update: async function(id,verified)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/update';

        var postData = new FormData();

        postData.append("id", id);
        postData.append("verified", verified);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    settle: async function(driver_id,amount)
    {
        var url = 'https://admin.pup.a2hosted.com/driver/settle';

        var postData = new FormData();

        postData.append("driver_id", driver_id);
        postData.append("amount", amount);

        return await HTTP.post(url, postData).then(response =>{return response;})
    }
}

export default DriverModel;