import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';

import './addAdminView.scss';


export default class AddAdminView extends View
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            name:null,
            mobile:null,
            email:null,
            lang:Session.getUserInfo()?.lang,
            roles:[1,2,3,4,5,6,7,8,9]

        }
    }

    handleChange(e)
    {
        this._isMounted && this.setState({...this.state, [e.target.name]:e.target.value});
    }
    
    handleChangeRoles(num)
    {
        if(this.state?.roles?.includes(num))
        {
            let temp = this.state?.roles.filter(item => item !== num)
            this._isMounted && this.setState({...this.state, roles:temp});
        }
        else
        {
            let temp = this.state?.roles
            temp.push(num)
            this._isMounted && this.setState({...this.state, roles:temp});
        }
    }

    handleUserTypeChange(value) 
	{
		this._isMounted && this.setState({ ...this.state, userType:value});
    }

    save()
    {
        this._isMounted && this.props?.save(this.state); 
    }
   
    render()
    {  
        const prefs = Session.getPreferences();

        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-3 `}>
                            <Col xs={12} sm={6} className={`p-0 m-0`}>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_name')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="name"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_name')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_mobile')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="mobile"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('+966-XXXXXXXXXX')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('tableHeader_email')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="email"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('tableHeader_email')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <div className={`${this.state?.roles?.includes(1) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,1)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(1) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('create_admins')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(2) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,2)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(2) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('view_orders')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(3) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,3)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(3) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('view_drivers')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(4) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,4)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(4) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('create_push_notification')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(5) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,5)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(5) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('view_app_config')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(6) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,6)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(6) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('add_user_balance')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(7) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,7)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(7) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('transfer_money_to_drivers')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(8) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,8)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(8) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('view_users')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                <div className={`${this.state?.roles?.includes(9) ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.handleChangeRoles.bind(this,9)}>
                                    <Row className={`m-0 p-2`}>
                                        <div className={`d-flex`}> 
                                            <div className={`${prefs?.dir}-float-to-trailing `}>
                                                <span className={`productCheckoutView-dot ${this.state?.roles?.includes(9) ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                            </div>
                                            <div className={`font-md px-3 py-1`}>
                                                {this.i18n('create_coupon')}
                                            </div>
                                        
                                        </div>
                                    </Row>   
                                </div>
                                

                                <Row className={`m-0 pt-3 pb-4 font-md t-text-alternative-with-alpha`}>
                                    <CustomButton
                                        iconAndText={false}
                                        viewIsLoading={false}
                                        disabled={false}
                                        style={`p-3 w-100 borderless customModal-button t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                        text={this.i18n('action_send')}
                                        action={this.save.bind(this)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                   
            </Container>
        );
    }
}