import HTTP from '../helper/http';

const OrderModel =
{
    getAll: async function(pageNumber,timestamp)
    {
        var url = 'https://admin.pup.a2hosted.com/order/getAll';

        var postData = new FormData();
        postData.append("page_number", pageNumber);
        postData.append("timestamp", timestamp);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getInfo: async function(id)
    {
        var url = 'https://admin.pup.a2hosted.com/order/getInfo';

        var postData = new FormData();
        postData.append("id", id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    }
}

export default OrderModel;