import HTTP from '../helper/http';

const UserModel =
{
    getAll: async function(pageNumber,timestamp)
    {
        var url = 'https://admin.pup.a2hosted.com/user/getAll';

        var postData = new FormData();
        postData.append("page_number", pageNumber);
        postData.append("timestamp", timestamp);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    getInfo: async function(id)
    {
        var url = 'https://admin.pup.a2hosted.com/user/getInfo';

        var postData = new FormData();
        postData.append("id", id);

        return await HTTP.post(url, postData).then(response =>{return response;})
    },

    update: async function(id,verified)
    {
        var url = 'https://admin.pup.a2hosted.com/user/update';

        var postData = new FormData();

        postData.append("id", id);
        postData.append("verified", verified);

        return await HTTP.post(url, postData).then(response =>{return response;})
    }
}

export default UserModel;