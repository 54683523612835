import ViewController from '../../core/viewController';

import ListView from "../../view/component/listView/listView";

import Formatter from '../../helper/formatter';
import Session from '../../helper/session';
import appConfigs from '../../config/appConfigs';


import UserModel from "../../model/user";


export default class UserViewController extends ViewController 
{
    constructor(props) {
		super(props);

		this.state =
		{
            list: [],
            isMainOpenDropdown:false,
            mainValue:"user",
			mainLabel:this.i18n('title_user'),
            loadMore:false,
        }

        this._shouldLoadMoreUser = true;
		this._pageNumberUser = 1;
		this._itemFormForUser = 1;
        this._itemToForUser = 25;
        this._currentTimestamp = Formatter?.getCurrentDate();

        this._listOfMainDropdown = 
        [
			{ "value": "driver", "label": this.i18n('title_user')},
        ];

        this._mainColForUser = 
        [
            { title: this.i18n('common_id'), isHidden: false },
            { title: this.i18n('common_name'), isHidden: false },
            { title: this.i18n('common_mobile'), isHidden: true },
            { title: this.i18n('common_balance'), isHidden: true },
            { title: "", isHidden: false }
        ];

        this._listOfKeysForUser = 
        [
            { key: "id", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
            { key: "name", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
		    { key: "mobile", isBoolean: false, boolTrue: this.i18n('common_closed'), boolFalse: this.i18n('common_active'), needTranslation: false, translation: "", isHidden: true },
            { key: "balance", isBoolean: false, boolTrue: this.i18n('common_yes'), boolFalse: this.i18n('common_no'), needTranslation: false, translation: `report_`, isHidden: true }
        ];

       
    }

    loadContentUser(type)
	{
		if (type === "more") {
			if (this.state.loadMore && this.state?.list?.length > 0) 
            {
				this._itemFormForUser += appConfigs.itemTo;
				this._itemToForUser += appConfigs.itemTo;
				this._pageNumberUser += 1;
            	this._isMounted && this.getUsers();
			}
		}
		else {
			if (this._pageNumberUser > 1) {
				this._pageNumberUser -= 1;
				this._itemFormForUser -= appConfigs.itemTo;
				this._itemToForUser -= appConfigs.itemTo;
				this._isMounted && this.getUsers();
			}
		}
    }

    getUsers() 
	{
		this._isMounted && this.startLoading();
		this._isMounted && UserModel
		?.getAll(this._pageNumberUser, this._currentTimestamp)
		.then(async data => 
		{
			if(parseInt(data?.result) === 1)
            {
                if(data?.data?.length < appConfigs.itemTo )
				{
					if(data?.length !==0)
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false, list: data?.data, totelOfUser: data?.count});
				    }
					else
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false});
					}
				}
				else
				{
					this._isMounted && this.setState({ ...this.state, loadMore:true, list: data?.data, totelOfUser: data?.count });
				}
                this._isMounted && this.stopLoading();
            }
		})
		.catch(e => {
			this._isMounted && this.stopLoading();
		});
    }

    handleClickEvent(id)
    {
        this.props?.history?.push(`/user/${id}`);
    }
    
    viewControllerDidMount()
	{
        this.setTitle("title_user");
        this._isMounted && this.getUsers();
    }

    render()
    {
        // Session.setLoggedIn(); 

        if(Session.isLoggedIn())
        {
        return (
            <ListView
                viewIsLoading={this.state?.viewIsLoading}
                shouldShowMainDropdown={true}
                isMainOpenDropdown={this.state?.isMainOpenDropdown}
                mainLabel={this.state?.mainLabel}
                listOfMainDropdown={this._listOfMainDropdown}
                title={this.i18n('title_user')}
                shouldShowFristFilter={false}
                shouldShowSecondFilter={false}
                shouldShowThirdFilter={false}

                identifier={"id"}

                

                mainCol={this._mainColForUser}
                items={this.state?.list}
                listOfKeys={this._listOfKeysForUser}

                numberOfItem={this.state?.totelOfUser}
                itemFrom={this._itemFormForUser}
                itemTo={this._itemToForUser }
                pageNumber={this._pageNumberUser}

                
                handleClickEvent={this.handleClickEvent.bind(this)}
                loadContent={this.loadContentUser.bind(this)}
            />
        )
        }
        else
        {
            window.location.href = "/login";
        }
    }
}