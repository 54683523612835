import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';
import EmptyState from "../../component/emptyState/emptyState";

import avatar_user from "../../../assets/images/avatar_user.png";

export default class UriverView extends View
{
    update(verified)
    {
        this._isMounted && this.props?.update(verified); 
    }

    render()
    {  
        const prefs = Session.getPreferences();
        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100 p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                    <Row className={`m-0 p-0 pt-5 `}>
                        <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                            {this.i18n('title_user')}
                        </div>
                        <Col xs={12} sm={6} className={`p-0 m-0`}>
                            <Row className={`m-0 p-0 py-4`}>
                                <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading t-alternative-with-alpha`}>
                                    {
                                        <div style={{backgroundImage :  `url(${avatar_user})`}} className={`manageUserView-user-avatar`}/>
                                    }
                                </Col>
                                    
                            </Row>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_displayName')}:`}
                                </Col>
                                
                                <Col xs={7} sm={8} md={7} lg={8} xl={6} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.user?.name}
                                </Col>
                                
                            </Row>
                            <Row className={`m-0 py-3 d-flex align-items-center font-md t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('formAttribute_mobile')}:`}
                                </Col>
                                {
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={6} className={`p-0 ${this.props?.userInfo?.is_mobile_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                        {this.props?.user?.mobile}
                                    </Col>
                                }
                            </Row>
                                
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_amount')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={6} className={`p-0 manageUserView-notVerified`}>
                                    {this.props?.user?.balance}
                                </Col>
                            </Row>
                                
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_accountStatus')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={5} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.user?.is_active? this.i18n('common_active'):this.i18n('common_deactivate')}
                                </Col>
                            </Row>
                            <Row className={`m-0 pt-5 pb-4 font-md t-text-alternative-with-alpha px-3`}>
                                <CustomButton
                                    iconAndText={false}
                                    viewIsLoading={false}
                                    disabled={false}
                                    style={`p-3 w-100 borderless customModal-button t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                    text={this.i18n('action_activate')}
                                    action={this.update.bind(this,this.props?.driver?.verified)}
                                />
                            </Row>

                        </Col>
                        <Col xs={12} sm={6} className={`p-0 m-0 px-3 ${prefs?.dir}-manageUserView-item-container ${prefs?.dir === "rtl" ? `t-br-alternative` : `t-bl-alternative`}`}>
                            <Row className={`m-0 py-3  font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('title_orders')}:`}
                                </Col>
                                
                            </Row>
                            <Row className={`m-0 py-3  font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                {this.props?.user?.orders?.length > 0
                                ?
                                    <div className="h-100 scrollable-y mx-3">
                                        <Row className={`p-0 m-0 py-4 px-2 scanQRView-history-container t-${prefs?.theme}-bb-alternative`}>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 ${prefs?.dir}-pull-to-leading`}>
                                                {`${this.i18n('id')}`}
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 text-center `}>
                                                {`${this.i18n('common_packageTitle')}`}
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 ${prefs?.dir}-pull-to-trailing`}>
                                                {`${this.i18n('common_status')}`}
                                            </Col>
                                        </Row>
                                        {this.props?.user?.orders?.map((item, index) => 
                                        {
                                            return (
                                                <Link className="naked" to={`/order/${item?.id}`}>
                                                    <Row key={index} className={`m-0 p-0 py-2 my-2 px-2 t-${prefs?.theme}-bg-secondary-highlight rounded-sm pointer opacity-hover`} >
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 ${prefs?.dir}-pull-to-leading`}>
                                                                {item?.id}
                                                            </Col>
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 text-center`}>
                                                                {prefs?.dir === "rtl" ? item?.packageTypeAR : item?.packageTypeEN}
                                                            </Col>
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={`p-0 m-0 ${prefs?.dir}-pull-to-trailing`}>
                                                                {prefs?.dir === "rtl" ? item?.statusAR : item?.statusEN}
                                                            </Col>
                                                    </Row>
                                                </Link>

                                            )
                                        })}
                                    </div>
                                :
                                    <EmptyState 
                                        shouldShowIcon={false}
                                        text={this.i18n('empty_state_item')}
                                    />
                                }
                                
                            </Row>
                        </Col>
                                

                    
                    </Row>
                   
            </Container>
        );
    }
}