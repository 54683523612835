import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import NotificationsView from '../../view/page/notifications/notificationsView';


import NotificationModel from "../../model/notification";

import Session from '../../helper/session';

export default class NotificationsViewController extends ViewController 
{
	constructor(props) {
		super(props);

		this.state =
		{
        }
	}

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}

	save(temp)
	{
		this._isMounted && this.startLoading()
		this._isMounted && NotificationModel
		?.createNotification(temp)
		.then(async data =>	
		{
			if(parseInt(data?.result) === 1)
			{
				this._isMounted && this.showErrMsg(this.i18n("msg_changesSaved"), "success");
				window.location='/overview'
				
			
			}
			else
			{
				this._isMounted && this.showErrMsg(data?.message, "error");
			}
		})
		.catch(e =>
		{
			this._isMounted && this.stopLoading();		
		});
	}
    
	viewControllerDidMount()
	{
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<NotificationsView
						save={this.save.bind(this)}
										
					/>
				</>
			);
		}
        else 
        {
            window.location.href = "/login";
        }
    }
}