import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import UserView from '../../view/page/user/userView';

import UserModel from "../../model/user";
import Session from '../../helper/session';


export default class UserIDViewController extends ViewController {
	constructor(props) {
		super(props);

		this.state =
		{
			user_id:props.match?.params?.id,
			user:{},
        }
	}

	getInfo()
	{
        this._isMounted && this.startLoading()
        this._isMounted && UserModel
        ?.getInfo(this.state?.user_id)
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, user:data?.data,});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
		
    }

	update(verified)
	{
		this._isMounted && this.startLoading()
        this._isMounted && UserModel
        ?.update(this.state?.user_id, verified === 1 ? 0 : 1)
        .then(async data =>
        {
			if(parseInt(data?.result) === 1)
			{
				this._isMounted && this.getInfo();
				this._isMounted && this.showErrMsg(this.i18n("msg_changesSaved"), "success");
			}
			else
			{
				this._isMounted && this.showErrMsg(data?.message, "error");
			}
			

        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
	}
	
	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}
    
	viewControllerDidMount()
	{
		this._isMounted && this.getInfo();
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<UserView
						user={this.state?.user}
						update={this.update.bind(this)}

					/>
					
				</>
			);
		}
        else 
        {
            window.location.href = "/login";
        }
	}
}