import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';
import avatar_user from "../../../assets/images/avatar_user.png";



export default class UriverView extends View
{
    buttonAction()
    {
        alert('hi')
    }

    render()
    {  
        const prefs = Session.getPreferences();
        console.log(this.props?.order);
        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                    <Row className={`m-0 p-0 pt-5 `}>
                        <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                            {this.i18n('title_order')}
                        </div>
                        <Col xs={12} sm={6} className={`p-0 m-0`}>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_packageTitle')}:`}
                                </Col>
                                
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.order?.packageTitle}
                                </Col>
                                
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_packageSize')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.order?.sizeEN}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('commom_receiverName')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.order?.receiverName}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('commom_receiverPhone')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                {this.props?.order?.receiverPhone}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('commom_not')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.order?.note}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_customerName')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                {this.props?.order?.customer_name}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_customerPhone')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {this.props?.order?.customer_phone}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_status')}:`}
                                </Col>
                                <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                    {prefs?.dir === "rtl" ? this.props?.order?.statusAR : this.props?.order?.statusEN}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} className={`m-0 ${prefs?.dir}-manageUserView-item-container ${prefs?.dir === "rtl" ? `t-br-alternative` : `t-bl-alternative`}`}>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_fromAddress')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                    {this.props?.order?.from_address}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_toAddress')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                    {this.props?.order?.to_address}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_driverName')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                     {this.props?.order?.driver_name ? this.props?.order?.driver_name : this.i18n('common_unknown')}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('commom_driverPhone')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                    {this.props?.order?.driver_phone ? this.props?.order?.driver_phone : this.i18n('common_unknown')}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                    {`${this.i18n('common_price')}:`}
                                </Col>
                                <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                    {this.props?.order?.total}
                                </Col>
                            </Row>
                            <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_image')}:`}
                                    </Col>
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <div style={{backgroundImage : this.props?.order?.car_back ? `url(${this.props?.order?.car_back})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>
                                </Row>
                        </Col>
                    </Row>
                   
                </Container>
            );
        }
    }         