import View from '../../../core/view';

import AppConfigs from '../../../config/appConfigs';

import CustomButton from "../button/customButton.js";

import Session from "../../../helper/session";

import "./counter.scss"

export default class Counter extends View 
{
    constructor(props) 
    {
        super(props)

        this.state =
        {
            counter: AppConfigs.otpExpirationTime,
            isSendAgain: false,
        }

        this._timer = 0;
    }

    handleCounterButton(data) 
    {
        this._isMounted && this.props.handleCounterButton(data);
    }

    requestNewOTPBySMS() 
    {
        this._isMounted && this.setState({ ...this.state, counter: AppConfigs.otpExpirationTime });
        this._isMounted && this.props.requestNewOTPBySMS();
    }

    requestNewOTPByEmail() 
    {
        this._isMounted && this.setState({ ...this.state, counter: AppConfigs.otpExpirationTime });
        this._isMounted && this.props.requestNewOTPByEmail();
    }

    viewDidMount() 
    {
        this._isMounted = true;
        this._timer = this.state.counter >= 0 && setInterval(() => 
        {
            this._isMounted && this.setState({ ...this.state, counter: this.state.counter - 1 })
            if (this.state.counter === 0) 
            {
                this.setState({ ...this.state, isSendAgain: true })
            }
        }, 1000);
    }

    viewWillUnmount() 
    {
        this._isMounted = false;
        clearInterval(this._timer);
    }

    render() 
    {
        const prefs = Session.getPreferences();
        return (
            <>
                <>
                    {/* <div className={`m-0 p-0`}>
                        <p className={`d-inline-block py-4 text-center t-text-primary-highlight p-0 font-md`}>
                            {this.i18n('common_codeExpires')}
                            {this.state.counter < 0
                                ?
                                <span className={`${prefs.altrDir} px-2 t-text-blue`}>{`1:00`}</span>
                                :
                                <span className={`${prefs.altrDir} px-2 t-text-blue`}>{(Math.floor(this.state.counter / 60) < 10) ? "0" + Math.floor(this.state.counter / 60) : Math.floor(this.state.counter / 60)}:{((this.state.counter % 60) < 10) ? "0" + (this.state.counter % 60) : (this.state.counter % 60)}</span>}
                        </p>
                    </div> */}

                    {this.state.isSendAgain
                        ?
                        <CustomButton
                            iconAndText={false}
                            viewIsLoading={this.props.viewIsLoading}
                            disabled={this.props?.viewIsLoading || this.state.counter > 0}
                            style={`m-0 my-2 mt-3 buttons-padding w-100 borderless custom-modal-button ${prefs?.dir}-secondaryFont font-md t-bg-accent t-text-primary-highlight rounded-sm`}
                            text={this.i18n('action_resendOTP')}
                            action={this.props?.canUseSMS ? this.requestNewOTPBySMS.bind(this) : this.requestNewOTPByEmail.bind(this)}
                        />
                        :
                        null
                    }
                </>

                <CustomButton
                    iconAndText={false}
                    viewIsLoading={this.props.viewIsLoading}
                    disabled={this.props?.viewIsLoading || (this.props?.dataLength !== this.props?.data?.length)}
                    style={`m-0 buttons-padding mt-3 mb-4 w-100 borderless custom-modal-button t-text-primary-highlight t-bg-accent font-md rounded-sm`}
                    text={this.props.buttonText}
                    action={this.handleCounterButton.bind(this, this.props?.data)}
                />
            </>
        )
    }
}
