import ViewController from '../../core/viewController';
import FreeLayoutView from '../../view/layout/free/freeLayout';

import LoginView from '../../view/page/preLogin/loginView';
import VerifyOTPView from '../../view/page/verifyOTP/verifyOtpView';

import Session from '../../helper/session';
import ResetPasswordView from '../../view/page/resetPassword/resetPassword';
import Validator from '../../helper/validator';
import AdminModel from "../../model/admin";


export default class LoginViewController extends ViewController {
    constructor(props) {
        super(props);

        this.state =
        {
            isOTPview: false,
            isMobile: false,
            isEmail: false,
            isResetPassword: false,
            isConfirmPassword: false,
            mobileNumber:'',
            loginInfo: {},
        };

    }

    processOTP(otp) 
    {
        this._isMounted && this.startLoading();
        this._isMounted && AdminModel
        ?.verifyOTP(this.state?.identifier,otp)
        .then(async data =>
        {
            this._isMounted && this.stopLoading();
            if (parseInt(data?.result) === 1) 
            {
                this._isMounted && Session?.setToken(data?.token);
                this._isMounted && Session?.setUserInfo(data?.user);
                this._isMounted && Session?.setLoggedIn("1")

                window.location.href = "/overview";

            }
            else
            {
                this._isMounted && this.showErrorMsg(data?.error_msg, "error");
            }
        })
        .catch(e => {
            this._isMounted && this.stopLoading();
        });
    }

    requestNewOTP() 
    {
       

    }

    handleBackEvent() 
    {
        this._isMounted && this.setState({ ...this.state, isOTPview: false });
    }

    login(identifier) 
    {
        if (Validator.isNotEmpty(identifier)) 
        {
            const prefs = Session.getPreferences();
            this._isMounted && this.startLoading();
            this._isMounted && AdminModel
            ?.login(identifier,prefs?.lang)
            .then(async data =>
            {
                this._isMounted && this.stopLoading();
                if (parseInt(data?.result) === 1) 
                {
                    
                    this._isMounted && this.setState({ ...this.state, isOTPview:true,identifier:identifier});
                }
                else
                {
                    this._isMounted && this.showErrorMsg(data?.error_msg, "error");
                }
            })
            .catch(e => {
                this._isMounted && this.stopLoading();
            });
        }
        else 
        {
            this._isMounted && this.showErrorMsg(this.i18n('validation_allFieldsRequired'), "error");
        }
    }

    handleResetPasswordEvent(isMobileSelected) 
    {
        this._isMounted && this.setState({ ...this.state, isResetPassword: !this.state.isResetPassword, isMobile: isMobileSelected })
    }

    viewControllerDidMount() 
    {
        this.setTitle("title_login");
    }


    render() 
    {
        if (Session.isLoggedIn() === "1") 
        {
            window.location.href = "/overview";
        }
        else 
        {
            return (
                <FreeLayoutView>
                    {
                        this.state?.isOTPview
                        ?
                            <VerifyOTPView
                                otpLength={[0, 1, 2, 3, 4, 5]}
                                isMultiInputs={true}
                                shouldShowCounter={true}
                                viewIsLoading={false}
                                shouldShowGoBack={true}
                                requestNewOTP={this.requestNewOTP.bind(this)}
                                processOTP={this.processOTP.bind(this)}
                                closeOtpModal={this.handleBackEvent.bind(this)}
                            />
                        
                        :
                        this.state?.isResetPassword
                        ?
                            <ResetPasswordView
                                otpLength={[0, 1, 2, 3, 4, 5]}
                                isMultiInputs={true}
                                canUseEmail={!this.state.isMobile}
                                canUseSMS={this.state.isMobile}
                                shouldShowCounter={true}
                                viewIsLoading={false}
                                handleResetPasswordEvent={this.processResetPassword.bind(this)}
                                handleBackEvent={this.handleBackEvent.bind(this)}
                                requestNewOTP={this.requestNewOTP.bind(this)}
                                isConfirmPassword={this.state.isConfirmPassword}
                                isResetPassword={this.state.isResetPassword}
                                sendCode={this.sendCode.bind(this)}
                                loginInfo={this.state.loginInfo}
                            />
                        :
                            <LoginView
                                viewIsLoading={this.state?.viewIsLoading}
                                login={this.login.bind(this)}
                                handleResetPasswordEvent={this.handleResetPasswordEvent.bind(this)}
                            />
                    }
                </FreeLayoutView>
            )
        }
    }
}
