import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';

import Session from '../../helper/session';

import AdminModel from "../../model/admin";
import AddAdminView from '../../view/page/admin/addAdminView';


export default class AddAdminViewController extends ViewController 
{
	constructor(props) {
		super(props);

		this.state =
		{
        }
	}

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}

	save(temp)
	{
		this._isMounted && this.startLoading()
		this._isMounted && AdminModel
		?.add(temp)
		.then(async data =>
		{
            if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.showErrMsg(this.i18n("msg_changesSaved"), "success");
                window.location='/admin';
                
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.message, "error");
            }
		})
		.catch(e =>
		{
			this._isMounted && this.stopLoading();		
		});
	}
    
	viewControllerDidMount()
	{
	}
	
	render() {
        if (Session.isLoggedIn()) 
        {
            return (
                <>
                    <LoadingTopBar
                        val={this.state?.loadingBarProgress}
                    />
                    
                    <AddAdminView
                        save={this.save.bind(this)}
                    />
                </>
            );
        }
        else 
        {
            window.location.href = "/login";
        }
        
    }
}