import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import OrderView from '../../view/page/order/orderView';
import OrderModel from "../../model/order";

import Session from '../../helper/session';

export default class OrderIDViewController extends ViewController 
{
	constructor(props) {
		super(props);

		this.state =
		{
			order_id:props.match?.params?.id,
			order:{}
        }
	}

	getInfo()
	{
        this._isMounted && this.startLoading()
        this._isMounted && OrderModel
        ?.getInfo(this.state?.order_id)
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, order:data?.order,});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
		
    }

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}
    
	viewControllerDidMount()
	{
		this._isMounted && this.getInfo();
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<OrderView
						order={this.state?.order}
					/>
					
				</>
			);
		}
        else 
        {
            window.location.href = "/login";
        }
	}
}