import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';
import avatar_user from "../../../assets/images/avatar_user.png";
import './driverView.scss';
export default class DriverView extends View
{
    update(verified)
    {
        this._isMounted && this.props?.update(verified); 
    }

    render()
    {  
        const prefs = Session.getPreferences();

        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-5 `}>
                            <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                                {this.i18n('title_driver')}
                            </div>
                            <Col xs={12} sm={6} className={`p-0 m-0`}>
                                
                                <Row className={`m-0 p-0 py-4`}>
                                    <Col className={`p-0 m-0 ${prefs?.dir}-pull-to-leading t-alternative-with-alpha`}>
                                        {
                                            <div style={{backgroundImage : this.props?.driver?.profile_pic ? `url(${this.props?.driver?.profile_pic})` : `url(${avatar_user})`}} className={`manageUserView-user-avatar`}/>
                                        }
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_displayName')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.name}
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 d-flex align-items-center font-md t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('formAttribute_mobile')}:`}
                                    </Col>
                                    {
                                        
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_mobile_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                            {this.props?.driver?.mobile}
                                        </Col>
                                    }
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={5} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_accountStatus')}:`}
                                    </Col>
                                    <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.verified? this.i18n('common_active'):this.i18n('common_deactivate')}
                                    </Col>
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_bank')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.bankEN}
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_iban')}:`}
                                    </Col>
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.bank_IBAN}
                                    </Col>
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_totalAmountTransferred')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.driver?.amount_transfered}
                                    </Col>
                                    
                                </Row>
                                
                                
                                <Row className={`m-0 pt-5 pb-4 pe-4 font-md t-text-alternative-with-alpha px-3`}>
                                    <CustomButton
                                        iconAndText={false}
                                        viewIsLoading={false}
                                        disabled={false}
                                        style={`p-3 w-100 borderless customModal-button t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                        text={this.props?.driver?.verified ? this.i18n('action_deactivate') : this.i18n('action_activate')}
                                        action={this.update.bind(this,this.props?.driver?.verified)}
                                    />
                                </Row>

                            </Col>
                            <Col xs={12} sm={6} className={`m-0 ${prefs?.dir}-manageUserView-item-container ${prefs?.dir === "rtl" ? `t-br-alternative` : `t-bl-alternative`}`}>

                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_license')}:`}
                                    </Col>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <div style={{backgroundImage : this.props?.driver?.car_license ? `url(${this.props?.driver?.car_license})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_id')}:`}
                                    </Col>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <div style={{backgroundImage : this.props?.driver?.id_image ? `url(${this.props?.driver?.id_image})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_carRegistration')}:`}
                                    </Col>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                <div style={{backgroundImage : this.props?.driver?.car_license ? `url(${this.props?.driver?.car_license})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0`}>
                                        {`${this.i18n('common_carImage')}:`}
                                    </Col>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <div style={{backgroundImage : this.props?.driver?.car_front ? `url(${this.props?.driver?.car_front})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>

                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha px-2 ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <div style={{backgroundImage : this.props?.driver?.car_back ? `url(${this.props?.driver?.car_back})` : `url(${avatar_user})`}} className={`tournamentDetailsView-game-image`}/>

                                </Row>

                            </Col>
                        </Row>
                   
            </Container>
        );
    }
}