import View from '../../../core/view';

import { Container, Row, Col } from 'reactstrap';

import Counter from "../../component/counter/counter";

import Session from '../../../helper/session';

import SmallModal from '../../component/smallModal/smallModal'

import './verifyOtpView.scss';

export default class VerifyOTPView extends View {
    constructor(props) {
        super(props);
        this.state =
        {
            counterUnquieID: Math.random(),
            otp: [],
            otpSingleInput: ""
        }

        this._otp = [];
        this._timer = 0;
    }

    otpForm(e, index) {
        if (this.props?.isMultiInputs) {
            const [fieldName, fieldIndex] = e.target.name.split("-");
            this._otp[index] = e.target.value;

            if (e.target.value.length === e.target.maxLength) {
                if (fieldName && parseInt(fieldIndex, 10) < 6) {
                    const nextSibling = document.querySelector(
                        `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
                    );

                    if (nextSibling !== null) {
                        nextSibling.focus();
                    }
                }
            }
            else {
                if (fieldName && parseInt(fieldIndex, 10) < 6) {
                    const prevSibling = document.querySelector(
                        `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                    );
                    if (prevSibling !== null) {
                        prevSibling.focus();
                    }
                }
            }

            this._isMounted && this.setState({ ...this.state, otp: this._otp.join('') });

        }
        else {
            this._isMounted && this.setState({ ...this.state, otpSingleInput: e.target.value });
        }

    }

    processOTP() {
        this._isMounted && this.props.processOTP(this.state.otp);
    }

    requestNewOTP(method) {
        this._isMounted && this.props.requestNewOTP(method);
    }

    handleBackEvent() {
        this._isMounted && this.props?.closeOtpModal();
    }


    render() {
        const prefs = Session.getPreferences();
        return (
            <Container className="themed-container m-0 p-0" fluid={true}>
                <Row className="m-0 p-4 h-100 justify-content-center">
                    <Col className={`m-0 p-0 loginView-col align-self-center`}>
                        <div className={`m-0 p-0 ${prefs?.theme === "light" ? 't-light-bg-exception' : `t-bg-secondary`} t-text-alternative-highlight rounded-md`}>
                            <SmallModal
                                shouldShowBackButton={true}
                                handleBackEvent={this.handleBackEvent.bind(this)}
                            />
                            <Container className={`themed-container m-0 p-0 px-2 px-md-4 ${prefs?.dir}-secondaryFont t-text-alternative `} fluid={true}>
                                <Row className={`m-0 p-0 pb-3 t-text-alternative-highlight`}>
                                    <p className={`p-0 pb-3 font-lg ${prefs?.dir}-primaryFont`}>
                                        {this.i18n('formAttribute_login')}
                                    </p>
                                </Row>
                                <Row className={`m-0 p-0 ltr pt-2 justify-content-between`}>
                                    {this.props?.isMultiInputs
                                        ?
                                        <>
                                            {this.props?.otpLength.map((item, index) => {
                                                return (
                                                    <div className={`p-0 m-0 optView-otp-input-container`} key={index}>
                                                        <input
                                                            onChange={(e) => this.otpForm(e, index)}
                                                            type="text"
                                                            autoComplete="off"
                                                            name={`otp-${index}`}
                                                            maxLength="1"
                                                            placeholder={"____"}
                                                            className={`inputs-padding w-100 t-input borderless ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent optView-otp-input font-sm rounded-sm`}
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </>
                                        :
                                        <input onChange={(e) => this.otpForm(e, 0)} type="text" name="otpSingleInput" autoComplete="off" className={`p-3 w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-secondary`} t-text-primary-highlight t-caret-color-accent font-sm rounded-sm`} placeholder={this.i18n('formPlaceholder_otp')} />
                                    }

                                    <div className={`p-0 m-0 pt-1 text-center`}>
                                        <Counter
                                            canUseSMS={this.props.canUseSMS}
                                            canUseEmail={this.props.canUseEmail}
                                            key={this.state.counterUnquieID}
                                            dataLength={this.props?.isMultiInputs ? this.props?.otpLength?.length : this.state?.otpSingleInput?.length}
                                            data={this.props.isMultiInputs ? this.state.otp : this.state.otpSingleInput}
                                            viewIsLoading={this.props?.viewIsLoading}
                                            handleCounterButton={this.processOTP.bind(this)}
                                            requestNewOTPBySMS={this.requestNewOTP.bind(this, 'sms')}
                                            requestNewOTPByEmail={this.requestNewOTP.bind(this, 'email')}
                                            buttonText={this.i18n('formAttribute_login')}
                                        />
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}