import ViewController from '../../core/viewController';

import ListView from "../../view/component/listView/listView";


import DriverModel from "../../model/driver";

import Formatter from '../../helper/formatter';
import appConfigs from '../../config/appConfigs';
import Session from '../../helper/session';

export default class DriverViewController extends ViewController 
{
    constructor(props) {
		super(props);

		this.state =
		{
            list: [],
            isMainOpenDropdown:false,
            driverType: null,
			driverStatus: null,
            driverReason: null,
            mainValue:"drivers",
			mainLabel:this.i18n('title_driver'),
            loadMore:false,
        }

        this._shouldLoadMoreDriver = true;
		this._pageNumberDriver = 1;
		this._itemFormForDriver = 1;
        this._itemToForDriver = 25;
        this._currentTimestamp = Formatter?.getCurrentDate();

        this._listOfMainDropdown = 
        [
			{ "value": null, "label": this.i18n('formPlaceholder_filterByTypes')},
            { "value": "unverified", "label": this.i18n('common_unverified')},
            { "value": "verified", "label": this.i18n('common_verified')},
        ];

        this._mainColForDriver = 
        [
            { title: this.i18n('common_id'), isHidden: false },
            { title: this.i18n('common_name'), isHidden: false },
            { title: this.i18n('common_mobile'), isHidden: true },
            { title: this.i18n('common_verified'), isHidden: true },
            { title: "", isHidden: false }
        ];

        this._listOfKeysForDriver = 
        [
            { key: "id", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
            { key: "name", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
		    { key: "mobile", isBoolean: false, boolTrue: this.i18n('common_closed'), boolFalse: this.i18n('common_active'), needTranslation: false, translation: "", isHidden: true },
            { key: "verified", isBoolean: true, boolTrue: this.i18n('common_yes'), boolFalse: this.i18n('common_no'), needTranslation: false, translation: `report_`, isHidden: true }
        ];

       
    }

    loadContentDriver(type)
	{
		if (type === "more") {
			if (this.state.loadMore && this.state?.list?.length > 0) 
            {
				this._itemFormForDriver += appConfigs.itemTo;
				this._itemToForDriver += appConfigs.itemTo;
				this._pageNumberDriver += 1;
            	this._isMounted && this.getDrivers();
			}
		}
		else {
			if (this._pageNumberDriver > 1) {
				this._pageNumberDriver -= 1;
				this._itemFormForDriver -= appConfigs.itemTo;
				this._itemToForDriver -= appConfigs.itemTo;
				this._isMounted && this.getDrivers();
			}
		}
    }

    handleChangeFristFilter(value)
    {
        this._isMounted && this.setState({ ...this.state, driverStatus:value, list:[] }, function () 
        {
			this._pageNumberDriver = 1
			this._isMounted && this.getDrivers();
		});
    }

    getDrivers() 
	{
		this._isMounted && this.startLoading();
		this._isMounted && DriverModel
		?.getAll(this._pageNumberDriver, this._currentTimestamp,this.state?.driverStatus)
		.then(async data => 
		{
			if(parseInt(data?.result) === 1)
            {
                if(data?.data?.length < appConfigs.itemTo )
				{
					if(data?.length !==0)
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false, list: data?.data, totelOfDriver: data?.count});
				    }
					else
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false});
					}
                    
				}
				else
				{
					this._isMounted && this.setState({ ...this.state, loadMore:true, list: data?.data, totelOfDriver: data?.count });
				}
                this._isMounted && this.stopLoading();
            }
		})
		.catch(e => {
			this._isMounted && this.stopLoading();
		});
    }

    handleClickEvent(id)
    {
        this.props?.history?.push(`/driver/${id}`);
    }
    
    viewControllerDidMount()
	{
        this.setTitle("title_driver");
        this._isMounted && this.getDrivers();
    }

    render()
    {
        // Session.setLoggedIn(); 

        if(Session.isLoggedIn())
        {
        return (
            <ListView
                viewIsLoading={this.state?.viewIsLoading}
                shouldShowMainDropdown={true}
                isMainOpenDropdown={this.state?.isMainOpenDropdown}
                mainLabel={this.state?.mainLabel}
                listOfMainDropdown={this._listOfMainDropdown}
                title={this.i18n('title_driver')}

                shouldShowFristFilter={true}
                placeholderFristFilter={this.i18n('title_driver')}
                fristFilterList={this._listOfMainDropdown}
                handleChangeFristFilter={this.handleChangeFristFilter.bind(this)}

                identifier={"id"}



                

                mainCol={this._mainColForDriver}
                items={this.state?.list}
                listOfKeys={this._listOfKeysForDriver}

                numberOfItem={this.state?.totelOfDriver}
                itemFrom={this._itemFormForDriver}
                itemTo={this._itemToForDriver }
                pageNumber={this._pageNumberDriver}

                
                handleClickEvent={this.handleClickEvent.bind(this)}
                loadContent={this.loadContentDriver.bind(this)}
                
            />
        )
        }
        else
        {
            window.location.href = "/login";
        }
    }
}