import ViewController from '../../core/viewController';

import ListView from "../../view/component/listView/listView";

import Formatter from '../../helper/formatter';
import OrderModel from "../../model/order";
import appConfigs from '../../config/appConfigs';


import Session from '../../helper/session';

export default class OrderViewController extends ViewController 
{
    constructor(props) {
		super(props);

		this.state =
		{
            list: [],
            isMainOpenDropdown:false,

            
            mainValue:"order",
			mainLabel:this.i18n('title_order'),
        }
        const prefs = Session.getPreferences();
        this._shouldLoadMoreOrder = true;
		this._pageNumberOrder = 1;
		this._itemFormForOrder = 1;
        this._itemToForOrder = 25;
        this._currentTimestamp = Formatter?.getCurrentDate();

        this._listOfMainDropdown = 
        [
			{ "value": "order", "label": this.i18n('title_order')},
        ];

        this._mainColForOrder = 
        [
            { title: this.i18n('common_id'), isHidden: false },
            { title: this.i18n('common_packageTitle'), isHidden: false },
            { title: this.i18n('common_price'), isHidden: true },
            { title: this.i18n('common_status'), isHidden: true },
            { title: "", isHidden: false }
        ];

        this._listOfKeysForOrder = 
        [
            { key: "id", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
            { key: "packageTitle", isBoolean: false, boolTrue: "", boolFalse: "", needTranslation: false, translation: "",needDateFormatter:false, isHidden: false },
            { key: "price", isBoolean: false, boolTrue: this.i18n('common_closed'), boolFalse: this.i18n('common_active'), needTranslation: false, translation: "", isHidden: true },
            { key: prefs?.dir === "rtl" ? "statusAR" : "statusEN", isBoolean: false, boolTrue: this.i18n('common_yes'), boolFalse: this.i18n('common_no'), needTranslation: false, translation: `report_`, isHidden: true }
        ];

       
    }

    loadContentOrder(type)
	{
		if (type === "more") {
			if (this.state.loadMore && this.state?.list?.length > 0) 
            {
				this._itemFormForOrder += appConfigs.itemTo;
				this._itemToForOrder += appConfigs.itemTo;
				this._pageNumberOrder += 1;
            	this._isMounted && this.getOrders();
			}
		}
		else {
			if (this._pageNumberOrder > 1) {
				this._pageNumberOrder -= 1;
				this._itemFormForOrder -= appConfigs.itemTo;
				this._itemToForOrder -= appConfigs.itemTo;
				this._isMounted && this.getOrders();
			}
		}
    }

    getOrders() 
	{
		this._isMounted && this.startLoading();
		this._isMounted && OrderModel
		?.getAll(this._pageNumberOrder, this._currentTimestamp)
		.then(async data => 
		{
			if(parseInt(data?.result) === 1)
            {
                if(data?.data?.length < appConfigs.itemTo )
				{
					if(data?.length !==0)
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false, list: data?.data, totelOfOrder: data?.count});
				    }
					else
					{
						this._isMounted && this.setState({ ...this.state, loadMore:false});
					}
                    
				}
				else
				{
					this._isMounted && this.setState({ ...this.state, loadMore:true, list: data?.data, totelOfOrder: data?.count });
				}
                this._isMounted && this.stopLoading();
            }
		})
		.catch(e => {
			this._isMounted && this.stopLoading();
		});
    }

    handleClickEvent(id)
    {
        this.props?.history?.push(`/order/${id}`);
    }
    
    viewControllerDidMount()
	{
        this.setTitle("title_order");
        this._isMounted && this.getOrders();
    }

    render()
    {
        // Session.setLoggedIn(); 

        if(Session.isLoggedIn())
        {
        return (
            <ListView
                viewIsLoading={this.state?.viewIsLoading}
                shouldShowMainDropdown={true}
                isMainOpenDropdown={this.state?.isMainOpenDropdown}
                mainLabel={this.state?.mainLabel}
                listOfMainDropdown={this._listOfMainDropdown}
                title={this.i18n('title_order')}
                shouldShowFristFilter={false}
                shouldShowSecondFilter={false}
                shouldShowThirdFilter={false}
                identifier={"id"}



                

                mainCol={this._mainColForOrder}
                items={this.state?.list}
                listOfKeys={this._listOfKeysForOrder}

                numberOfItem={this.state?.totelOfOrder}
                itemFrom={this._itemFormForOrder}
                itemTo={this._itemToForOrder }
                pageNumber={this._pageNumberOrder}
                handleClickEvent={this.handleClickEvent.bind(this)}


                

                loadContent={this.loadContentOrder.bind(this)}
                
            />
        )
        }
        else
        {
            window.location.href = "/login";
        }
    }
}