import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';

import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';
import SearchableDropdownMenu from "../../component/searchableDropdownMenu/searchableDropdownMenu";

export default class CouponView extends View
{
    constructor(props)
    {
        super(props)
    
        this.state =
        {
            code:null,
            max_uses:null,
            type:"rate",
        }
        this._list = [{ "value": "rate", "label": this.i18n('common_Percentage')},
                    { "value": "amount", "label": this.i18n('common_amount')}]
    }

    create()
    {
        this._isMounted && this.props?.create(this.state); 
    }

    handleChange(e)
    {
        this._isMounted && this.setState({...this.state, [e.target.name]:e.target.value});
    }

    handleTypeChange(value) 
	{
		this._isMounted && this.setState({ ...this.state, type:value});
    }

    render()
    {  
        const prefs = Session.getPreferences();

        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-5 `}>
                            <Col xs={12} sm={6} className={`p-0 m-0`}>
                                <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                                    {this.i18n('title_coupon')}
                                </div>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_couponId')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="code"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_couponId')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('formAttribute_max_uses')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="max_uses"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('formAttribute_max_uses')}
                                        />
                                    </Col>
                                    
                                </Row>
                            
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}/>
                                    
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <SearchableDropdownMenu 
                                            dir={prefs?.dir}
                                            theme={prefs?.theme} 
                                            shouldShowInput={false}
                                            isSearchable={false}
                                            list={this._list}
                                            menuPlaceholder={this.props?.placeholderFristFilter}
                                            defaultMenuValue={this._list?.[0].value}
                                            y_padding={3}
                                            menuLabelKeys={["label"]}
                                            menuValueKey={"value"}
                                            onMenuChange={this.handleTypeChange.bind(this)}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_discountPercentage')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="identifier"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_discountPercentage')}
                                        />
                                    </Col>
                                    
                                </Row>
                                
                                <Row className={`m-0 pt-5 pb-4 font-md t-text-alternative-with-alpha`}>
                                    <CustomButton
                                        iconAndText={false}
                                        viewIsLoading={false}
                                        disabled={false}
                                        style={`p-3 w-100 borderless customModal-button t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                        text={this.i18n('action_create')}
                                        action={this.create.bind(this)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                   
            </Container>
        );
    }
}