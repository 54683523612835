import ViewController from '../../core/viewController';

import Session from '../../helper/session';
import Formatter from '../../helper/formatter';

import GeneralModel from "../../model/general";

import OverviewView from '../../view/page/overview/overviewView';
import FreeLayoutView from '../../view/layout/free/freeLayout';


export default class OverviewViewController extends ViewController 
{
    constructor(props)
	{
		super(props)
	
		this.state =
		{
            overalldata: {},
		}
        this._currentTimestamp = Formatter?.getCurrentDate();
	}
    
    getOverAll()
    {
        this._isMounted && this.startLoading()
        this._isMounted && GeneralModel
        ?.getOverview()
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, overalldata:data?.data[0]});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
    }



    viewControllerDidMount()
	{
        this._isMounted && this.getOverAll();
    }

    render()
    {
        if(Session.isLoggedIn())
        {
            return (
                <FreeLayoutView>
                    <OverviewView 
                        viewIsLoading={this.state?.viewIsLoading}
                        overalldata={this.state?.overalldata}
                    />
                </FreeLayoutView>
            )
        }
        else
        {
            window.location.href = "/login";
        }
    }
}
