import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';

import CustomButton from '../../component/button/customButton';
import SearchableDropdownMenu from "../../component/searchableDropdownMenu/searchableDropdownMenu";



export default class NotificationsView extends View
{
    constructor(props) 
    {
        super(props);

        this.state =
        {
            titleEN:null,
            messageEN:null,
            titleAR:null,
            messageAR:null,
            userType:null,
        }
        this._list = [{ "value": "driver", "label": this.i18n('title_driver')},
        { "value": "user", "label": this.i18n('title_user')},
        { "value": "all", "label": this.i18n('formPlaceholder_filterByTypes')}]
    }

    handleChange(e)
    {
        this._isMounted && this.setState({...this.state, [e.target.name]:e.target.value});
    }

    handleUserTypeChange(value) 
	{
		this._isMounted && this.setState({ ...this.state, userType:value});
    }

    save()
    {
        this._isMounted && this.props?.save(this.state); 
    }
   
    render()
    {  
        const prefs = Session.getPreferences();

        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-5 `}>
                            <Col xs={12} sm={6} className={`p-0 m-0`}>
                                <div className={`p-0 m-0 t-text-alternative ${prefs?.dir}-primaryFont font-bold font-lg ${prefs?.dir}-pull-to-leading pb-4`}>
                                    {this.i18n('title_notifications')}
                                </div>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('commom_massageEn')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="messageEN"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('commom_massageEn')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_titleEn')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="titleEN"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_titleEn')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('commom_massageAr')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="messageAR"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('commom_massageAr')}
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('common_titleAr')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <input
                                            onChange={this.handleChange.bind(this)}
                                            type="text"
                                            name="titleAR"
                                            defaultValue={""}
                                            autoComplete="off"
                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-secondary t-caret-color-accent font-sm rounded-sm`}
                                            placeholder={this.i18n('common_titleAr')}
                                        />
                                    </Col>
                                    
                                </Row>
                                
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={4} md={5} lg={4} xl={3} className={`p-0 pt-2`}>
                                        {`${this.i18n('commom_receiverPhone')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        <SearchableDropdownMenu 
                                            dir={prefs?.dir}
                                            theme={prefs?.theme} 
                                            shouldShowInput={false}
                                            isSearchable={false}
                                            list={this._list}
                                            menuPlaceholder={this.props?.placeholderFristFilter}
                                            defaultMenuValue={this._list?.[0].value}
                                            y_padding={3}
                                            menuLabelKeys={["label"]}
                                            menuValueKey={"value"}
                                            onMenuChange={this.handleUserTypeChange.bind(this)}
                                        />
                                    </Col>
                                    
                                </Row>
                                
                                <Row className={`m-0 pt-5 pb-4 font-md t-text-alternative-with-alpha`}>
                                    <CustomButton
                                        iconAndText={false}
                                        viewIsLoading={false}
                                        disabled={false}
                                        style={`p-3 w-100 borderless customModal-button t-text-primary-highlight ${prefs?.dir}-secondaryFont t-bg-secondary font-md rounded-sm`}
                                        text={this.i18n('action_send')}
                                        action={this.save.bind(this)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                   
            </Container>
        );
    }
}