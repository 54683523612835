import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/ar';
import Session from './session';

const Formatter =
{


    getLinks: function (text)
    {
        var pattern = /(?:(?:https?|http):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%]+/gi;
        return text?.match(pattern);
    },

    from24To12:function (time24)
    {
        var [sHours, minutes, seconds] = time24.match(/([0-9]{1,2}):([0-9]{2}):([0-9]{2})/).slice(1);
        var period = +sHours < 12 ? 'am' : 'pm';
        var hours = +sHours % 12 || 12;

        if(hours.toString()?.length < 2)
        {
            hours = "0" + hours;
        }

        return {hour:hours.toString(), minute:minutes.toString(), second:seconds.toString(), period:period.toString()};
    },

    from12To24:function(time12)
    {
        var [sHours, minutes, seconds, period] = time12.match(/([0-9]{1,2}):([0-9]{2}):([0-9]{2}) (am|pm)/).slice(1);
        var PM = period === 'pm';
        var hours = (+sHours % 12) + (PM ? 12 : 0);

        if(hours.toString()?.length < 2)
        {
            hours = "0" + hours;
        }

        return {hour:hours.toString(), minute:minutes.toString(), second:seconds.toString(), period:period.toString()};
    },

    numberToStringWithCurrency: function (amount, withDecimals, currencyCode, dirCode, langCode) {
        
        var placementKey = "placement_" + dirCode;
        var symbolKey = "symbol_" + langCode;

        var options = 
        {
            "sar":{"placement_ltr":"before",
                    "placement_rtl":"after",
                    "symbol_default":" SR ",
                    "symbol_ar":" ر.س. ",
                    "rate":1},
                    
            "usd":{"placement_ltr":"before",
                    "placement_rtl":"before",
                    "symbol_default":" $ ",
                    "symbol_ar":" $ ",
                    "rate":0.266},

            "eur":{"placement_ltr":"before",
                    "placement_rtl":"before",
                    "symbol_default":" € ",
                    "symbol_ar":" يورو ",
                    "rate":0.266},

            "aed":{"placement_ltr":"before",
                "placement_rtl":"before",
                "symbol_default":" AED ",
                "symbol_ar":" د.إ. ",
                "rate":0.266},

            "bhd":{"placement_ltr":"before",
                "placement_rtl":"before",
                "symbol_default":" BHD ",
                "symbol_ar":" د.ب. ",
                "rate":0.266},

            "kwd":{"placement_ltr":"before",
                "placement_rtl":"before",
                "symbol_default":" KWD ",
                "symbol_ar":" د.ك.",
                "rate":0.266},

            "omr":{"placement_ltr":"before",
                "placement_rtl":"before",
                "symbol_default":" OMR ",
                "symbol_ar":" ر.ع. ",
                "rate":0.266}
        };
        
        if(options[currencyCode] !== undefined)
        {
            var placement = options[currencyCode]["placement_rtl"];
            var symbol = options[currencyCode]["symbol_default"];

            if(options[currencyCode][placementKey] !== undefined)
            {
                placement = options[currencyCode][placementKey];
            }

            if(options[currencyCode][symbolKey] !== undefined)
            {
                symbol = options[currencyCode][symbolKey];
            }

            var amountFormatted = parseFloat(amount)*options[currencyCode]["rate"];

            if(withDecimals)
            {
                amountFormatted = this.stringToNumberWithCommasAndDicimals(amountFormatted);
            }
            else{
                amountFormatted = this.stringToNumberWithCommas(amountFormatted);
            }

            if(placement === "before")
            {
                return symbol + amountFormatted; 
            }
            else
            {
                return amountFormatted + symbol; 
            }
        }
        else{
            return amount;
        }
    },

    stringToNumberWithCommas: function (number) 
    {
        if (number !== undefined && number !== null)
        {
            return parseFloat(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{
            return "";
        }
    },

    stringToNumberWithCommasAndDicimals: function (number)
    {
        if (number !== undefined && number !== null)
        {
            return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{
            return "";
        }
    },

    stringToAggregatedSum:function (number)
    {
        if(number < 9000)
        {
            return this.stringToNumberWithCommas(number)
        }
        else
        {
            if(number >= 1000000)
            {
                return Intl.NumberFormat().format(Math.round((number/1000000)*10)/10) + i18n.t("common_number_millions");
            }
            else if(number >= 11000)
            {
                return Intl.NumberFormat().format(Math.round((number/1000)*10)/10) + i18n.t("common_number_thousand");
            }            
            else
            {
                return Intl.NumberFormat().format(Math.round((number/1000)*10)/10) + i18n.t("common_number_thousands");
            }
        }
    },

    longStringToShortWithDots: function(text, max, dots)
    {
        return ((text)?.length > max)
        ? (((text)?.substring(0, max - dots)) + '.'.repeat(dots))
        : text
    },

    oneLineToMultiLines(content)
    {
        return content?.split("\n" || "\r")?.join("<br />");
    },

    getCurrentDate: function ()
    {
        var today = new Date(),
        yyyy = String(today.getFullYear()).padStart(2, '0'),
        mm = String(today.getMonth() + 1).padStart(2, '0'),
        dd = String(today.getDate()).padStart(2, '0'),
        hh = String(today.getHours()).padStart(2, '0'),
        ii = String(today.getMinutes()).padStart(2, '0'),
        ss = String(today.getSeconds()).padStart(2, '0'),
        
        timeFormatted = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
            
        return timeFormatted.toString()
    },

    timeSince: function(timestamp, timezone) 
    {
        moment.locale(i18n.language)
        var offset = this.getOffset(timezone);
        var output = moment.utc(timestamp).utcOffset(offset).fromNow();
        return (output === 'a few seconds ago' || output === 'منذ ثانية واحدة') ? i18n.t('common_justNow') : output;
    },

    fromLocalToUTC: function (timestamp=null)
    {
        let date = timestamp !== null ? new Date(timestamp.replace(/-/g, "/")) : new Date();

        const yyyy = String(date.getUTCFullYear()).padStart(2, '0');
        const mm = String(date.getUTCMonth() + 1).padStart(2, '0');
        const dd = String(date.getUTCDate()).padStart(2, '0');
        const hh = String(date.getUTCHours()).padStart(2, '0');
        const ii = String(date.getUTCMinutes()).padStart(2, '0');
        const ss = String(date.getUTCSeconds()).padStart(2, '0');
       
        const timeFormatted = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
 
        return timeFormatted.toString();
    },

    timestampToDateTimeString: function(_timestamp, timezone)
    {
        moment.locale(i18n.language);

        var offset = this.getOffset(timezone);
        var timestamp = moment.utc(_timestamp).utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
        
        var timestampFormatted = i18n.t("common_unknown");
        
        if (timestamp !== undefined && timestamp !== null)
        {
            const timestampArray = timestamp?.split(' ');
            if(timestampArray.length === 2)
            {
                const dateArray = timestampArray[0].split("-");
                const timeArray = timestampArray[1].split(":");

                if(dateArray.length === 3 && timeArray.length === 3)
                {
                    var monthName = "";
                    if(dateArray[1] === 1 || dateArray[1] === "1" || dateArray[1] === "01" || dateArray[1] === "٠١")
                    {
                        monthName = i18n.t("common_month_jan");
                    }
                    else if(dateArray[1] === 2 || dateArray[1] === "2" || dateArray[1] === "02"|| dateArray[1] === "٠٢")
                    {
                        monthName = i18n.t("common_month_feb");
                    } 
                    else if(dateArray[1] === 3 || dateArray[1] === "3" || dateArray[1] === "03"|| dateArray[1] === "٠٣")
                    {
                        monthName = i18n.t("common_month_mar");
                    } 
                    else if(dateArray[1] === 4 || dateArray[1] === "4" || dateArray[1] === "04"|| dateArray[1] === "٠٤")
                    {
                        monthName = i18n.t("common_month_apr");
                    } 
                    else if(dateArray[1] === 5 || dateArray[1] === "5" || dateArray[1] === "05"|| dateArray[1] === "٠٥")
                    {
                        monthName = i18n.t("common_month_may");
                    } 
                    else if(dateArray[1] === 6 || dateArray[1] === "6" || dateArray[1] === "06"|| dateArray[1] === "٠٦")
                    {
                        monthName = i18n.t("common_month_jun");
                    } 
                    else if(dateArray[1] === 7 || dateArray[1] === "7" || dateArray[1] === "07"|| dateArray[1] === "٠٧")
                    {
                        monthName = i18n.t("common_month_jul");
                    } 
                    else if(dateArray[1] === 8 || dateArray[1] === "8" || dateArray[1] === "08"|| dateArray[1] === "٠٨")
                    {
                        monthName = i18n.t("common_month_aug");
                    } 
                    else if(dateArray[1] === 9 || dateArray[1] === "9" || dateArray[1] === "09"|| dateArray[1] === "٠٩")
                    {
                        monthName = i18n.t("common_month_sep");
                    } 
                    else if(dateArray[1] === 10 || dateArray[1] === "10" || dateArray[1] === "10"|| dateArray[1] === "١٠")
                    {
                        monthName = i18n.t("common_month_oct");
                    }   
                    else if(dateArray[1] === 11 || dateArray[1] === "11" || dateArray[1] === "11"|| dateArray[1] === "١١")
                    {
                        monthName = i18n.t("common_month_nov");
                    }   
                    else if(dateArray[1] === 12 || dateArray[1] === "12" || dateArray[1] === "12"|| dateArray[1] === "١٢")
                    {
                        monthName = i18n.t("common_month_dec");
                    }                                                                                                                                            

                    timestampFormatted = dateArray[2] + " " + monthName + " " + dateArray[0];
                    timestampFormatted += " " + i18n.t("common_timestampAt") + " ";
                    timestampFormatted += timeArray[0] + ":" + timeArray[1];
                }
            }
        }
        return timestampFormatted;
    },

    timestampToTimestampLocal: function(_timestamp, timezone)
    {
        moment.locale('en');

        var offset = this.getOffset(timezone);
        var timestamp = moment.utc(_timestamp).utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
        
        return timestamp;
    },

    timestampToDateString: function(_timestamp, timezone)
    {
        moment.locale(i18n.language)
        var offset = this.getOffset(timezone);
        var timestamp = moment.utc(_timestamp).utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
        
        var timestampFormatted = i18n.t("common_unknown");

        if (timestamp !== undefined && timestamp !== null)
        {
            const timestampArray = timestamp.split(" ");
            if(timestampArray.length === 2)
            {
                const dateArray = timestampArray[0].split("-");

                if(dateArray?.length === 3)
                {
                    var monthName = "";

                    if(dateArray[1] === 1 || dateArray[1] === "1" || dateArray[1] === "01"|| dateArray[1] === "٠١")
                    {
                        monthName = i18n.t("common_month_jan");
                    }
                    else if(dateArray[1] === 2 || dateArray[1] === "2" || dateArray[1] === "02" || dateArray[1] === "٠٢")
                    {
                        monthName = i18n.t("common_month_feb");
                    } 
                    else if(dateArray[1] === 3 || dateArray[1] === "3" || dateArray[1] === "03" || dateArray[1] === "٠٣")
                    {
                        monthName = i18n.t("common_month_mar");
                    } 
                    else if(dateArray[1] === 4 || dateArray[1] === "4" || dateArray[1] === "04" || dateArray[1] === "٠٤")
                    {
                        monthName = i18n.t("common_month_apr");
                    } 
                    else if(dateArray[1] === 5 || dateArray[1] === "5" || dateArray[1] === "05" || dateArray[1] === "٠٥")
                    {
                        monthName = i18n.t("common_month_may");
                    } 
                    else if(dateArray[1] === 6 || dateArray[1] === "6" || dateArray[1] === "06" || dateArray[1] === "٠٦")
                    {
                        monthName = i18n.t("common_month_jun");
                    } 
                    else if(dateArray[1] === 7 || dateArray[1] === "7" || dateArray[1] === "07" || dateArray[1] === "٠٧")
                    {
                        monthName = i18n.t("common_month_jul");
                    } 
                    else if(dateArray[1] === 8 || dateArray[1] === "8" || dateArray[1] === "08" || dateArray[1] === "٠٨")
                    {
                        monthName = i18n.t("common_month_aug");
                    } 
                    else if(dateArray[1] === 9 || dateArray[1] === "9" || dateArray[1] === "09" || dateArray[1] === "٠٩")
                    {
                        monthName = i18n.t("common_month_sep");
                    } 
                    else if(dateArray[1] === 10 || dateArray[1] === "10" || dateArray[1] === "10" || dateArray[1] === "١٠")
                    {
                        monthName = i18n.t("common_month_oct");
                    }   
                    else if(dateArray[1] === 11 || dateArray[1] === "11" || dateArray[1] === "11" || dateArray[1] === "١١")
                    {
                        monthName = i18n.t("common_month_nov");
                    }   
                    else if(dateArray[1] === 12 || dateArray[1] === "12" || dateArray[1] === "12" || dateArray[1] === "١٢")
                    {
                        monthName = i18n.t("common_month_dec");
                    }                                                                                                                                            

                    timestampFormatted = dateArray[2] + " " + monthName + " " + dateArray[0];
                }
            }
        }
        return timestampFormatted;
    },


    dateToDateString: function(timestamp)
    {
        var timestampFormatted = i18n.t("common_unknown");

        if (timestamp !== undefined && timestamp !== null)
        {
            const dateArray = timestamp.split("-");

            if(dateArray.length === 3)
            {
                var monthName = "";

                if(dateArray[1] === 1 || dateArray[1] === "1" || dateArray[1] === "01")
                {
                    monthName = i18n.t("common_month_jan");
                }
                else if(dateArray[1] === 2 || dateArray[1] === "2" || dateArray[1] === "02")
                {
                    monthName = i18n.t("common_month_feb");
                } 
                else if(dateArray[1] === 3 || dateArray[1] === "3" || dateArray[1] === "03")
                {
                    monthName = i18n.t("common_month_mar");
                } 
                else if(dateArray[1] === 4 || dateArray[1] === "4" || dateArray[1] === "04")
                {
                    monthName = i18n.t("common_month_apr");
                } 
                else if(dateArray[1] === 5 || dateArray[1] === "5" || dateArray[1] === "05")
                {
                    monthName = i18n.t("common_month_may");
                } 
                else if(dateArray[1] === 6 || dateArray[1] === "6" || dateArray[1] === "06")
                {
                    monthName = i18n.t("common_month_jun");
                } 
                else if(dateArray[1] === 7 || dateArray[1] === "7" || dateArray[1] === "07")
                {
                    monthName = i18n.t("common_month_jul");
                } 
                else if(dateArray[1] === 8 || dateArray[1] === "8" || dateArray[1] === "08")
                {
                    monthName = i18n.t("common_month_aug");
                } 
                else if(dateArray[1] === 9 || dateArray[1] === "9" || dateArray[1] === "09")
                {
                    monthName = i18n.t("common_month_sep");
                } 
                else if(dateArray[1] === 10 || dateArray[1] === "10" || dateArray[1] === "10")
                {
                    monthName = i18n.t("common_month_oct");
                }   
                else if(dateArray[1] === 11 || dateArray[1] === "11" || dateArray[1] === "11")
                {
                    monthName = i18n.t("common_month_nov");
                }   
                else if(dateArray[1] === 12 || dateArray[1] === "12" || dateArray[1] === "12")
                {
                    monthName = i18n.t("common_month_dec");
                }                                                                                                                                            

                timestampFormatted = dateArray[2] + " " + monthName + " " + dateArray[0];
            }
            
        }
        return timestampFormatted;
    },    

    monthNumberToMonthName: function(monthNumber)
    {
        var monthName = i18n.t("common_unknown");

        if(monthNumber === 1 || monthNumber === "1" || monthNumber === "01")
        {
            monthName = i18n.t("common_month_jan");
        }
        else if(monthNumber === 2 || monthNumber === "2" || monthNumber === "02")
        {
            monthName = i18n.t("common_month_feb");
        } 
        else if(monthNumber === 3 || monthNumber === "3" || monthNumber === "03")
        {
            monthName = i18n.t("common_month_mar");
        } 
        else if(monthNumber === 4 || monthNumber === "4" || monthNumber === "04")
        {
            monthName = i18n.t("common_month_apr");
        } 
        else if(monthNumber === 5 || monthNumber === "5" || monthNumber === "05")
        {
            monthName = i18n.t("common_month_may");
        } 
        else if(monthNumber === 6 || monthNumber === "6" || monthNumber === "06")
        {
            monthName = i18n.t("common_month_jun");
        } 
        else if(monthNumber === 7 || monthNumber === "7" || monthNumber === "07")
        {
            monthName = i18n.t("common_month_jul");
        } 
        else if(monthNumber === 8 || monthNumber === "8" || monthNumber === "08")
        {
            monthName = i18n.t("common_month_aug");
        } 
        else if(monthNumber === 9 || monthNumber === "9" || monthNumber === "09")
        {
            monthName = i18n.t("common_month_sep");
        } 
        else if(monthNumber === 10 || monthNumber === "10" || monthNumber === "10")
        {
            monthName = i18n.t("common_month_oct");
        }   
        else if(monthNumber === 11 || monthNumber === "11" || monthNumber === "11")
        {
            monthName = i18n.t("common_month_nov");
        }   
        else if(monthNumber === 12 || monthNumber === "12" || monthNumber === "12")
        {
            monthName = i18n.t("common_month_dec");
        }

        return monthName;
    },

    getOffset: function (timezone)
    {
        var output = timezone?.split('GMT') ? timezone?.split('GMT') : [];
        return output[1] ? output[1] : '+00:00';
    },

    getNotificaitonCenterIcon: function(event_type) 
    {
        if(event_type === 'credit' || event_type === 'debit')
        {
            return 'WalletOutline'
        }
        else if(event_type === 'post_replay' ||
                event_type === 'match_will_start' ||
                event_type === 'playground_cancelled' ||
                event_type === 'playground_ended' ||
                event_type === 'playground_will_start' ||
                event_type === 'tournament_activated' ||
                event_type === 'tournament_cancelled' ||
                event_type === 'tournament_deactivated' ||
                event_type === 'tournament_ended' ||
                event_type === 'tournament_will_start'
        )
        {
            return 'GameControllerOutline'
        }
        else if(event_type === 'get_likes' || event_type === 'get_replay')
        {
            return 'HomeOutline'
        }
        else if(event_type === 'get_following' || event_type === 'user_referral')
        {
            return 'PersonOutline'
        }
        else if(event_type === 'get_invited_to_join' || event_type === 'get_join_request_accepted' || event_type === 'get_join_request_rejected')
        {
            return 'PersonAddOutline'
        }
        else if(event_type === 'new_massage_chat')
        {
            return 'ChatbubbleEllipsesOutline'
        }

        return null;
    },

    getNotificaitonCenterMessage: function(event_type, display_obj_name, display_obj_type)
    {
        const prefs = Session?.getPreferences();

        if(event_type === 'credit' || event_type === 'debit') 
        {
            return `${this.numberToStringWithCurrency(display_obj_name, true, prefs?.currency, prefs?.dir, prefs?.lang)} ${i18n.t(`notificationCenter_${event_type}`)}`
        }
        else if(event_type === 'get_invited_to_join')
        {
            return `${display_obj_name} ${i18n.t(`notificationCenter_${event_type}`)} ${i18n.t(`common_${display_obj_type}`)}`
        }
        else if(event_type === 'get_join_request_accepted' || event_type === 'get_join_request_rejected')
        {
            return i18n.t(`notificationCenter_${event_type}_${display_obj_name}`)
        }
        else if(event_type === 'playground_cancelled' || event_type === 'playground_ended' || event_type === 'playground_will_start' ||
                event_type === 'tournament_activated' || event_type === 'tournament_cancelled' || event_type === 'tournament_deactivated' || 
                event_type === 'tournament_ended' || event_type === 'tournament_will_start' ||
                event_type === 'match_will_start'
        )
        {
            return i18n.t(`notificationCenter_${event_type}`)
        }
        else if(event_type === 'get_likes' || event_type === 'get_replay' || event_type === 'post_replay' || event_type === 'new_massage_chat' || event_type === 'get_following' || event_type === 'user_referral')
        {
            return `${display_obj_name} ${i18n.t(`notificationCenter_${event_type}`)}`
        }
        return null;
    },

    getNotificaitonCenterDestination: function(event_type, display_obj_type, first_param, second_param) 
    {
        if(event_type === 'credit' || event_type === 'debit') 
        {
            return `/wallet/${first_param}`
        }
        else if(event_type === 'get_invited_to_join')
        {
            return {pathname: '/settings', state:{activeName: 'invitations', subViewName: display_obj_type === 'tournament' && second_param === 'manage' ? 'tournamentManage' : display_obj_type === 'tournament' && second_param === 'play' ? 'tournamentPlay' : display_obj_type === 'playground' ? 'playgroundManage' : display_obj_type === 'team' ? 'TeamManage' : undefined}}
        }
        else if((event_type === 'get_join_request_accepted' || event_type === 'get_join_request_rejected') && display_obj_type === 'tournament')
        {
            return `/play/tournament/${first_param}`
        }
        else if((event_type === 'get_join_request_accepted' || event_type === 'get_join_request_rejected') && display_obj_type === 'playground')
        {
            return `/play/playground/${first_param}`
        }
        else if((event_type === 'get_join_request_accepted' || event_type === 'get_join_request_rejected') && display_obj_type === 'team')
        {
            return `/teamup/${first_param}`
        }
        else if((event_type === 'match_will_start' && display_obj_type === 'playground') || event_type === 'playground_cancelled' || event_type === 'playground_ended' || event_type === 'playground_will_start')
        {
            return `/play/playground/${first_param}`
        }
        else if((event_type === 'match_will_start' && display_obj_type === 'tournament') || event_type === 'post_replay' || event_type === 'tournament_activated' || event_type === 'tournament_cancelled' || event_type === 'tournament_deactivated' || event_type === 'tournament_ended' || event_type === 'tournament_will_start')
        {
            return `/play/tournament/${first_param}`
        }
        else if(event_type === 'get_likes' || event_type === 'get_replay')
        {
            return `/discover/post/${first_param}`
        }
        else if(event_type === 'get_following' || event_type === 'user_referral')
        {
            return `/${first_param}`
        }
        else if(event_type === 'new_massage_chat')
        {
            return '/talk'
        }
        return null;
    },
    
}

export default Formatter;