import View from '../../../core/view';

import { Container, Row, Col } from 'reactstrap';

import Counter from "../../component/counter/counter";
import SearchableDropdownMenu from "../../component/searchableDropdownMenu/searchableDropdownMenu";
import CustomButton from "../../component/button/customButton.js";
import SmallModal from '../../component/smallModal/smallModal';

import Session from '../../../helper/session';

import AppConfigs from '../../../config/appConfigs';

export default class ResetPasswordView extends View {
    constructor(props) {
        super(props);
        this.state =
        {
            counterUnquieID: Math.random(),
            otp: [],
            otpSingleInput: "",
            passwordInfo: {},
            identifer: '',
            isMobileInput: this.props.canUseSMS,
        }

        this._otp = [];
        this._timer = 0;
        this._servedCountries = [{
            country_code_iso_2: "BH",
            country_code_iso_3: "BHR",
            country_id: 48,
            name: "Bahrain",
            phone_code_iso: "973",
            phone_number_length: null,
            phone_prefix_number: null
        },
        {
            country_code_iso_2: "SA",
            country_code_iso_3: "SAU",
            country_id: 682,
            name: "Saudi Arabia",
            phone_code_iso: "966",
            phone_number_length: 12,
            phone_prefix_number: 5,
        },
        {
            country_code_iso_2: "IN",
            country_code_iso_3: "IND",
            country_id: 680,
            name: "India",
            phone_code_iso: "91",
            phone_number_length: 10,
            phone_prefix_number: 2,
        }
    ];
        this._PhoneCountryCode = AppConfigs?.defualtCountryPhoneCode;
    }

    otpForm(e, index) 
    {
        if (this.props?.isMultiInputs) 
        {
            const [fieldName, fieldIndex] = e.target.name.split("-");
            this._otp[index] = e.target.value;

            if (e.target.value.length === e.target.maxLength) 
            {
                if (fieldName && parseInt(fieldIndex, 10) < 6) 
                {
                    const nextSibling = document.querySelector(
                        `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
                    );

                    if (nextSibling !== null) 
                    {
                        nextSibling.focus();
                    }
                }
            }
            else 
            {
                if (fieldName && parseInt(fieldIndex, 10) < 6) 
                {
                    const prevSibling = document.querySelector(
                        `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                    );
                    if (prevSibling !== null) 
                    {
                        prevSibling.focus();
                    }
                }
            }
            this._isMounted && this.setState({ ...this.state, otp: this._otp.join('') });
        }
        else 
        {
            this._isMounted && this.setState({ ...this.state, otpSingleInput: e.target.value });
        }
    }

    onKeyDownSubmit() 
    {
        if (this.props.viewIsLoading || (this.state.passwordInfo?.newPassword && this.state.passwordInfo?.confirmPassword)) 
        {
            this._isMounted && this.props.handleResetPasswordEvent(this.state?.otp, this.state.passwordInfo?.newPassword, this.state.passwordInfo?.confirmPassword);
        }
    }

    processLogin(newPassword, confirmPassword) 
    {
        this._isMounted && this.props.handleResetPasswordEvent(this.state?.otp, newPassword, confirmPassword, this.state?.identifer);
    }

    loginForm({ target: { name, value } }) 
    {
        let loginInfo = this.state.passwordInfo;
        loginInfo[name] = value;
        this._isMounted && this.setState({ ...this.state, passwordInfo: loginInfo })
    }

    requestNewOTP(isResetPassword = true) 
    {
        this._isMounted && this.props.requestNewOTP(isResetPassword);
    }

    handleBackEvent() 
    {
        this._isMounted && this.props?.handleBackEvent();
    }

    handlePhoneNumberChange(value) 
    {
        let mobileWithPhoneCoutryCode = `${this._PhoneCountryCode}${value}`;
        this._isMounted && this._isMounted && this.setState({ ...this.state, identifer: mobileWithPhoneCoutryCode })
    }

    handleEmailChange(e) 
    {
        this._isMounted && this._isMounted && this.setState({ ...this.state, identifer: e.target.value })

    }

    handleCountryCodeChange(phone_code) 
    {
        this._PhoneCountryCode = phone_code;
    }

    handleSwitch() 
    {
        this._isMounted && this.setState({ ...this.state, isMobileInput: !this.state.isMobileInput });
    }

    sendCode(identifer) 
    {
        this._isMounted && this.props.sendCode(identifer, this.state.isMobileInput);
    }

    render() 
    {
        const prefs = Session.getPreferences();
        return (
            <Container className="themed-container m-0 p-0 mx-2" fluid={true}>
                <Row className="m-0 p-4 h-100 justify-content-center">
                    <Col className={`m-0 p-0 loginView-col align-self-center`}>
                        <div className={` p-0 ${prefs?.theme === "light" ? 't-light-bg-exception' : `t-bg-secondary`} t-text-alternative-highlight rounded-md`}>
                            <SmallModal
                                shouldShowBackButton={true}
                                handleBackEvent={this.handleBackEvent.bind(this)}
                            />
                            <Container className={`themed-container m-0 p-0 px-md-4 px-sm-2 ${prefs?.dir}-secondaryFont t-text-alternative `} fluid={true}>
                                <Row className={`m-0 p-0 pb-3 t-text-alternative-highlight`}>
                                    <p className={`p-0 pb-3 font-lg ${prefs?.dir}-primaryFont`}>
                                        {this.i18n('formAttribute_resetPassword')}
                                    </p>
                                </Row>
                                {
                                    !this.props.isConfirmPassword
                                    ?
                                        <>
                                            <Row className={`m-0 p-0 px-3 px-md-0`}>
                                                <div className={`m-0 p-0 ${prefs.dir}-pull-to-leading loginView-dropdown`}>
                                                    <div className={`m-0 p-0 pb-3`}>
                                                        <span className={`pt-2 t-text-alternative-highlight font-md pointer`}>{this.state.isMobileInput ? this.i18n('formAttribute_mobile') : this.i18n('formAttribute_email')}</span>
                                                    </div>
                                                    {this.state.isMobileInput
                                                        ?
                                                        <>
                                                            <SearchableDropdownMenu
                                                                dir={"ltr"}
                                                                theme={prefs?.theme}
                                                                background={`t-bg-accent`}
                                                                shouldShowInput={true}
                                                                isSearchable={false}
                                                                list={this._servedCountries}
                                                                menuLabelKeys={["name", "phone_code_iso"]}
                                                                menuValueKey={"phone_code_iso"}
                                                                sortKey={"name"}
                                                                sortType={"ASC"}
                                                                y_padding={`inputs-padding`}
                                                                pe_paddingDropdown={`pe-2`}
                                                                defaultMenuValue={this._PhoneCountryCode}
                                                                defaultTextValue={this.state?.identifier}
                                                                placeholder={this.i18n('formPlaceholder_login_by_mobile')}
                                                                onTextChange={this.handlePhoneNumberChange.bind(this)}
                                                                onMenuChange={this.handleCountryCodeChange.bind(this)}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <input
                                                                onChange={this.handleEmailChange.bind(this)}
                                                                type="text"
                                                                name="identifier"
                                                                defaultValue={""}
                                                                autoComplete="off"
                                                                className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-alternative-highlight t-caret-color-accent font-sm rounded-sm`}
                                                                placeholder={this.i18n('formPlaceholder_login_by_email')}
                                                                onKeyDown={e => e.key === 'Enter' && this.onKeyDownSubmit()}
                                                            />
                                                        </>
                                                    }
                                                    <div className={`m-0 p-0 pt-3 pb-2 ${prefs?.dir}-pull-to-trailing`}>
                                                        <span onClick={this.handleSwitch.bind(this)} className={`pt-3 t-text-accent font-md pointer`}>{this.state.isMobileInput ? this.i18n('formAttribute_login_by_email') : this.i18n('formAttribute_login_by_mobile')}</span>
                                                    </div>
                                                </div>
                                                <div className={`m-0 p-0 pt-4 pb-4`}>
                                                    <CustomButton
                                                        iconAndText={false}
                                                        viewIsLoading={this.props.viewIsLoading}
                                                        disabled={this.props.viewIsLoading || !this.state.identifer}
                                                        style={`buttons-padding w-100 borderless custom-modal-button ${prefs?.dir}-secondaryFont font-md t-bg-accent t-text-alternative-highlight rounded-sm`}
                                                        text={this.i18n('action_send')}
                                                        action={this.sendCode.bind(this, this.state.identifer)}
                                                    />
                                                </div>
                                            </Row>
                                        </>
                                    :
                                        <>
                                            <Row className={`m-0 p-0 px-3 px-md-0`}>
                                                <div className={`m-0 p-0 pt-2 ${prefs.dir}-pull-to-leading loginView-dropdown`}>
                                                    <div className={`m-0 p-0 py-3`}>
                                                        <span className={`pt-2 t-text-alternative-highlight font-md`}>{this.i18n('common_sentCodeText')}</span>
                                                        <span className={`${prefs.altrDir} px-2 t-text-blue font-md`}>
                                                            {this.props.loginInfo}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className={`m-0 p-0 px-3 px-md-0 justify-content-between`}>
                                                {
                                                    this.props?.isMultiInputs
                                                    ?
                                                        <>
                                                            {this.props?.otpLength.map((item, index) => {
                                                                return (
                                                                    <div className="p-0 optView-otp-input-container" key={index}>
                                                                        <input
                                                                            onChange={(e) => this.otpForm(e, index)}
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            name={`otp-${index}`}
                                                                            maxLength="1"
                                                                            placeholder={"____"}
                                                                            className={`inputs-padding w-100 t-input borderless ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-alternative-highlight t-caret-color-accent optView-otp-input font-sm rounded-sm`}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                    :
                                                        <input onChange={(e) => this.otpForm(e, 0)} type="text" name="otpSingleInput" autoComplete="off" className={`p-3 w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-secondary`} t-text-alternative-highlight t-caret-color-accent font-sm rounded-sm`} placeholder={this.i18n('formPlaceholder_otp')} />
                                                }
                                                <Row className={`m-0 p-0 px-md-0`}>
                                                    <div className={`m-0 p-0 pt-3 ${prefs.dir}-pull-to-leading loginView-dropdown`}>
                                                        <div className={`m-0 p-0 py-3`}>
                                                            <span className={`pt-2 t-text-alternative-highlight font-md pointer`}>{this.i18n('formAttribute_newPassword')}</span>
                                                        </div>
                                                        <input
                                                            onChange={this.loginForm.bind(this)}
                                                            type={this.state.isPasswordVisible ? "text" : "password"}
                                                            name="newPassword"
                                                            defaultValue={""}
                                                            autoComplete="off"
                                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-alternative-highlight t-caret-color-accent font-sm rounded-sm`}
                                                            placeholder={this.i18n('formPlaceholder_new_password')}
                                                            onKeyDown={e => e.key === 'Enter' && this.onKeyDownSubmit()}
                                                        />
                                                    </div>
                                                    <div className={`m-0 p-0 py-4`}>
                                                        <div className={`m-0 p-0 pt-2 pb-3  ${prefs.dir}-pull-to-leading`}>
                                                            <span className={`pt-2 t-text-alternative-highlight font-md pointer`}>{this.i18n('formAttribute_confirmPassword')}</span>
                                                        </div>
                                                        <input
                                                            onChange={this.loginForm.bind(this)}
                                                            type={this.state.isPasswordVisible ? "text" : "password"}
                                                            name="confirmPassword"
                                                            autoComplete="off"
                                                            className={`inputs-padding w-100 t-input ${prefs?.dir}-custom-modal-border ${prefs?.theme === "light" ? 't-light-element' : `t-bg-primary`} t-text-alternative-highlight t-caret-color-accent font-sm rounded-sm`}
                                                            placeholder={this.i18n('formPlaceholder_confirm_password')}
                                                            onKeyDown={e => e.key === 'Enter' && this.onKeyDownSubmit()}
                                                        />
                                                    </div>
                                                </Row>
                                                <div className={`m-0 p-0 text-center`}>
                                                    <Counter
                                                        isResetPassword={true}
                                                        canUseSMS={this.props.canUseSMS}
                                                        canUseEmail={this.props.canUseEmail}
                                                        key={this.state.counterUnquieID}
                                                        dataLength={this.props?.isMultiInputs ? this.props?.otpLength?.length : this.state?.otpSingleInput?.length}
                                                        data={this.props.isMultiInputs ? this.state.otp : this.state.otpSingleInput}
                                                        viewIsLoading={this.props?.viewIsLoading}
                                                        handleCounterButton={this.processLogin.bind(this, this.state?.passwordInfo?.newPassword, this.state?.passwordInfo?.confirmPassword)}
                                                        requestNewOTPBySMS={this.requestNewOTP.bind(this)}
                                                        requestNewOTPByEmail={this.requestNewOTP.bind(this)}
                                                        buttonText={this.i18n('title_login')}
                                                    />
                                                </div>
                                            </Row>
                                        </>
                                }
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}