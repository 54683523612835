import View from '../../../core/view';

import {Container, Row, Col} from 'reactstrap';
import Session from '../../../helper/session';



export default class AdminView extends View
{
    buttonAction()
    {
        alert('hi')
    }

    updateRoles(role)
    {
        this._isMounted && this.props.updateRoles(role);
    }

    render()
    {  
        const prefs = Session.getPreferences();
        console.log(this.props?.admin);
        return (
                <Container className={`themed-container h-100 listView-scrollingArea scrollable-y ${prefs?.dir}-pull-to-leading h-100   p-0 px-3 px-md-5 pb-5 text-center ${prefs?.dir}-secondaryFont font-md ${prefs?.dir}-rounded-bottom`} fluid={true}>
                
                        <Row className={`m-0 p-0 pt-5 `}>
                            <Col xs={12} className={`p-0 m-0`}>
                                <Row className={`m-0 py-3 font-md t-text-alternative-with-alpha  ${this.props?.isEditable ? 'd-flex align-items-center py-1' : 'py-3'}`}>
                                    <Col xs={5} sm={3} md={3} lg={3} xl={1} className={`p-0`}>
                                        {`${this.i18n('common_displayName')}:`}
                                    </Col>
                                    
                                    <Col xs={7} sm={8} md={7} lg={8} xl={9} className={`p-0 t-text-tertiary-highlight manageUserView-notVerified`}>
                                        {this.props?.admin?.name}
                                    </Col>
                                    
                                </Row>
                                <Row className={`m-0 py-3 font-md d-flex align-items-center t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={3} md={3} lg={3} xl={1} className={`p-0`}>
                                        {`${this.i18n('formAttribute_email')}:`}
                                    </Col>
                                    {
                                       
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_email_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                             {this.props?.admin?.email}
                                        </Col>
                                    }
                                </Row>
                                <Row className={`m-0 py-3 d-flex align-items-center font-md t-text-alternative-with-alpha`}>
                                    <Col xs={5} sm={3} md={3} lg={3} xl={1} className={`p-0`}>
                                        {`${this.i18n('formAttribute_mobile')}:`}
                                    </Col>
                                    {
                                        
                                        <Col xs={7} sm={7} md={7} lg={8} xl={9} className={`p-0 ${this.props?.userInfo?.is_mobile_verified ? `manageUserView-verified`:`manageUserView-notVerified`}`}>
                                            {this.props?.admin?.mobile}
                                        </Col>
                                    }
                                </Row>
                                {this.props?.admin?.allRoles?.length > 0
                                ?
                                    this.props?.admin?.allRoles?.map((item, index) => 
                                    {
                                        return (
                                            <div key={index} className={`${item?.is_added ? `productCheckoutView-container` : ``} my-3 rounded-sm`} onClick={this.updateRoles.bind(this,item)}>
                                                <Row className={`m-0 p-2`}>
                                                    <div className={`d-flex`}> 
                                                        <div className={`${prefs?.dir}-float-to-trailing `}>
                                                            <span className={`productCheckoutView-dot ${item?.is_added ? `t-bg-primary-highlight productCheckoutView-dot-border t-border-accent` : `t-bg-alternative-highlight`} t-text-alternative-highlight`}></span>   
                                                        </div>
                                                        <div className={`font-md px-3 py-1`}>
                                                            {this.i18n(`${item?.role}`)}
                                                        </div>
                                                    
                                                    </div>
                                                </Row>   
                                            </div>
                                        )
                                    })
                                :
                                    null
                                }

                                

                            </Col>
                            
                        </Row>
                   
            </Container>
        );
    }
}