import ViewController from '../../core/viewController';

import LoadingTopBar from '../../view/component/loadingBar/loadingBar';
import DriverView from '../../view/page/driver/driverView';

import DriverModel from "../../model/driver";

import Session from '../../helper/session';


export default class DriverIdViewController extends ViewController {
	constructor(props) {
		super(props);

		this.state =
		{
			driver_id:props.match?.params?.id,
			driver:{},
        }
	}

	toggleModal(action, activeModal)
	{
		this._isMounted && this.props.toggleModal(action, activeModal);
	}

	goBack() 
	{
		this.props.history.goBack()
	}

	getInfo()
	{
        this._isMounted && this.startLoading()
        this._isMounted && DriverModel
        ?.getInfo(this.state?.driver_id)
        .then(async data =>
        {

			if(parseInt(data?.result) === 1)
            {
                this._isMounted && this.stopLoading()
                this._isMounted && this.setState({...this.state, driver:data?.data,});
            }
            else
            {
                this._isMounted && this.showErrMsg(data?.error_msg, "error");

            }
        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
    }

	update(verified)
	{
		this._isMounted && this.startLoading()
        this._isMounted && DriverModel
        ?.update(this.state?.driver_id, verified === 1 ? 0 : 1)
        .then(async data =>
        {
			if(parseInt(data?.result) === 1)
			{
				this._isMounted && this.getInfo();
				this._isMounted && this.showErrMsg(this.i18n("msg_changesSaved"), "success");
				
			}
			else
			{
				this._isMounted && this.showErrMsg(data?.message, "error");
			}

        })
        .catch(e =>
        {
            this._isMounted && this.stopLoading();		
        });
	}
    
	viewControllerDidMount()
	{
		this._isMounted && this.getInfo();
	}
	
	render() {
		if (Session.isLoggedIn()) 
        {
			return (
				<>
					<LoadingTopBar
						val={this.state?.loadingBarProgress}
					/>
					<DriverView
						driver={this.state?.driver}
						update={this.update.bind(this)}

					/>

					
				</>
			);
		}
        else 
        {
            window.location.href = "/login";
        }
	}
}